import "./ProductBox.css";
import noImage from "../assets/noImage.png";

export const ProductBox = ({ product, onClick, isSelected }) => {
  return (
    <div
      className="productbox__container"
      onClick={onClick}
      style={{ outline: isSelected ? "5px solid #2DBB54" : null }}
    >
      <div className="productbox__img-container">
        <img
          src={product?.imageObj?.thumbnail256256 || noImage}
          alt="product-img"
          className="productbox__img"
        />
      </div>
      <div className="productbox__text-container">
        <p className="productbox__text">{product?.name}</p>
      </div>
    </div>
  );
};
