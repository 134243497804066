import "./Home.css";
import { useEffect, useState, useRef } from "react";
import { Header } from "../components/Header";
import { NewsBox } from "../components/NewsBox";
import { NewsDetail } from "../components/NewsDetail";
import { Carousel } from "antd";
import { tR } from "../components/functions";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import WeatherCard from "../components/WeatherCard";
dayjs.locale("tr");

export const Home = () => {
  const adContainerRef = useRef(null);
  const [stories, setStories] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedList, setSelectedList] = useState("news");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [adContainerWidth, setAdContainerWidth] = useState(null);
  const [adContainerHeight, setAdContainerHeight] = useState(null);

  useEffect(() => {
    setAdContainerWidth(adContainerRef.current.clientWidth);
    setAdContainerHeight(adContainerRef.current.clientHeight);
    getStories();
  }, []);

  const getStories = async () => {
    const _stories = await tR(`/stories`, "GET");

    const last10Stories = _stories
      .sort((a, b) => dayjs(b.story.createdAt).diff(dayjs(a.story.createdAt)))
      .slice(0, 10);

    setStories(last10Stories);
  };

  const fetchList = async (endpoint) => {
    const category = await tR(`/${endpoint}/published`, "GET");
    setSelectedCategory(category);
  };

  const getNewsList = () => fetchList("news");
  const getAcademyList = () => fetchList("academy");
  const getResearchesList = () => fetchList("researches");

  useEffect(() => {
    if (selectedList === "news") {
      getNewsList();
    } else if (selectedList === "academy") {
      getAcademyList();
    } else if (selectedList === "researches") {
      getResearchesList();
    }
  }, [selectedList]);

  return (
    <div className="outer__container">
      <Header title="Hoşgeldiniz..." />
      <div className="home__content">
        <div className="home__left-col">
          <WeatherCard />
          <div className="home__ad-container" ref={adContainerRef}>
            <Carousel
              autoplaySpeed={5000}
              autoplay
              style={{ width: adContainerWidth, height: adContainerHeight }}
              draggable
            >
              {stories?.map((story) => (
                <div key={story.story.id}>
                  <div
                    style={{
                      height: adContainerHeight,
                      width: adContainerWidth,
                    }}
                  >
                    <img
                      className="home__ad-image-blur"
                      src={story.image.original}
                      alt="home-ad"
                    />
                    <img
                      className="home__ad-image-main"
                      src={story.image.original}
                      alt="home-ad"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="home__right-col">
          {!selectedNews ? (
            <>
              <div className="home__news-selection-div">
                <button
                  onClick={() => setSelectedList("news")}
                  className="home__news-selection-button"
                  style={{
                    backgroundColor: selectedList === "news" ? "#387d3b" : null,
                    color: selectedList === "news" ? "#fff" : null,
                  }}
                >
                  Haberler
                </button>
                <button
                  onClick={() => setSelectedList("academy")}
                  className="home__news-selection-button"
                  style={{
                    backgroundColor:
                      selectedList === "academy" ? "#387d3b" : null,
                    color: selectedList === "academy" ? "#fff" : null,
                  }}
                >
                  Faydalı Bilgiler
                </button>
                <button
                  onClick={() => setSelectedList("researches")}
                  className="home__news-selection-button"
                  style={{
                    backgroundColor:
                      selectedList === "researches" ? "#387d3b" : null,
                    color: selectedList === "researches" ? "#fff" : null,
                  }}
                >
                  Denemeler
                </button>
              </div>
              {selectedList === "researches" ? (
                <div
                  className="home__news-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "var(--text-color01)",
                  }}
                >
                  Yakında...
                </div>
              ) : (
                <div className="home__news-container">
                  {selectedCategory?.map((data) => (
                    <NewsBox
                      key={data.id}
                      title={data.title}
                      content={data.content}
                      image={data.image}
                      onClick={() =>
                        setSelectedNews((prev) => (prev === data ? null : data))
                      }
                      isExpanded={selectedNews === data}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="home__news-container">
              <NewsDetail
                selectedNews={selectedNews}
                setSelectedNews={setSelectedNews}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
