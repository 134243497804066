import "./Products.css";
import { useLocation } from "react-router-dom";
import { Header } from "../components/Header";
import { useEffect, useRef, useState } from "react";
import { Breadcrumb, Select } from "antd";
import productsMain from "../assets/productsMain.png";
import { ProductBox } from "../components/ProductBox";
import { tR } from "../components/functions";
import noImage from "../assets/noImage.png";
import { NotFoundContent } from "../components/NotFoundContent";

const renderTableItem = (key, value) => {
  return (
    <div className="products__green-table-item" key={key}>
      <p className="products__green-table-key">{key}</p>
      <p className="products__green-table-value">
        {value !== null && value !== 0 ? value : "-"}
      </p>
    </div>
  );
};

export const Products = () => {
  let { state } = useLocation();
  const productDetailsRef = useRef(null);

  const [upperSelection, setUpperSelection] = useState("bitkiBesleme");

  const [allProducts, setAllProducts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);

  const [updatedBrands, setUpdatedBrands] = useState([]);
  const [updatedCategories, setUpdatedCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("tumKategoriler");
  const [selectedBrand, setSelectedBrand] = useState("tumMarkalar");
  const [selectedProduct, setSelectedProduct] = useState(state?.option || null);

  const [newProductClicked, setNewProductClicked] = useState(false);
  const [breadcrumbBrandClicked, setBreadcrumbBrandClicked] = useState(false);

  const [selectedBrandInfo, setSelectedBrandInfo] = useState(null);
  const [productBreadcrumbInfo, setProductBreadcrumbInfo] = useState({
    productCategory: null,
    productBrand: null,
  });

  useEffect(() => {
    const selectedProductId = selectedProduct?.id;

    const foundProductInfo = (() => {
      for (const category of allCategories) {
        for (const brand of category.brands) {
          for (const product of brand.products) {
            if (product.id === selectedProductId) {
              return { productCategory: category, productBrand: brand };
            }
          }
        }
      }
      return { productCategory: null, productBrand: null };
    })();

    setProductBreadcrumbInfo(foundProductInfo);
  }, [selectedProduct, allCategories]);

  useEffect(() => {
    getAllCategories();
    getAllProducts();
  }, []);
  const getAllCategories = async () => {
    const _allCategories = await tR("/categories", "GET");
    setAllCategories(_allCategories);
  };
  const getAllProducts = async () => {
    const _products = await tR("/products", "GET");
    setAllProducts(_products);
  };

  useEffect(() => {
    setProducts(allProducts);
  }, [allProducts]);

  useEffect(() => {
    if (!breadcrumbBrandClicked) {
      setProducts(brands?.map((brand) => brand.products).flat());
    }
  }, [brands]);

  useEffect(() => {
    if (breadcrumbBrandClicked) {
      setProducts(productBreadcrumbInfo?.productBrand?.products ?? []);
    }
    setBreadcrumbBrandClicked(false);
  }, [productBreadcrumbInfo, breadcrumbBrandClicked]);

  useEffect(() => {
    const targetBrand = brands.find((brand) => brand.id === selectedBrand);

    if (targetBrand) {
      const brandMetaInfo = targetBrand?.brandMeta;
      setSelectedBrandInfo(brandMetaInfo);
    } else {
      console.error("Brand not found with the specified ID");
    }
  }, [selectedBrand, brands]);

  useEffect(() => {
    const _updatedCategories = allCategories?.map((category) => ({
      ...category,
      label: category.name,
      value: category.id,
    }));

    _updatedCategories.unshift({
      label: "Tüm Kategoriler",
      value: "tumKategoriler",
      brands: allProducts,
    });
    setUpdatedCategories(_updatedCategories);
  }, [allCategories]);

  useEffect(() => {
    const _updatedBrands = brands?.map((brand) => ({
      ...brand,
      label: brand.name,
      value: brand.id,
    }));

    _updatedBrands.unshift({
      label: "Tüm Markalar",
      value: "tumMarkalar",
      products: brands?.map((brand) => brand.products).flat(),
    });
    setUpdatedBrands(_updatedBrands);
  }, [brands]);

  const handleProductClick = () => {
    if (productDetailsRef.current) {
      productDetailsRef.current.scrollTop = 0;
    }
  };

  const islem = async () => {
    try {
      await tR(`/products/`, "PATCH", {});
    } catch (error) {
      console.error(`islemde hata:`, error);
    }
  };

  return (
    <div className="outer__container">
      <Header title="Ürünler" showBackButton={state && !newProductClicked} />
      {/* <button onClick={() => islem()}>islem yap</button> */}
      <div className="products__content">
        <div className="products__left">
          <div className="products__left-button-container">
            <div className="products__left-top-row">
              <button
                onClick={() => {
                  setUpperSelection("bitkiBesleme");
                  setSelectedProduct(null);
                }}
                className={`products__left-button ${
                  upperSelection === "bitkiBesleme" ? "selected" : ""
                }`}
              >
                Bitki Besleme
              </button>
              <button
                onClick={() => {
                  setUpperSelection("bitkiYetistirme");
                  setSelectedProduct(null);
                }}
                className={`products__left-button ${
                  upperSelection === "bitkiYetistirme" ? "selected" : ""
                }`}
              >
                Bitki Yetiştirme
              </button>
              <button
                onClick={() => {
                  setUpperSelection("tarimTeknolojileri");
                  setSelectedProduct(null);
                }}
                className={`products__left-button ${
                  upperSelection === "tarimTeknolojileri" ? "selected" : ""
                }`}
              >
                Tarım Teknolojileri
              </button>
            </div>
            <div className="products__left-top-row">
              <Select
                defaultValue={selectedCategory}
                value={
                  selectedCategory === "tumKategoriler"
                    ? "Tüm Kategoriler"
                    : selectedCategory
                }
                notFoundContent={
                  <NotFoundContent message="Kategori bulunamadı." />
                }
                className="products__select"
                showSearch
                placeholder="Kategori Seçiniz"
                optionFilterProp="children"
                onChange={(_, option) => {
                  if (option.label === "Tüm Kategoriler") {
                    setProducts(allProducts);
                    setBrands(
                      allCategories?.map((category) => category.brands).flat()
                    );
                  } else {
                    setBrands(option ? option.brands : []);
                  }
                  setSelectedCategory(option ? option.value : "tumKategoriler");
                  setSelectedProduct(null);
                  setSelectedBrand("tumMarkalar");
                  setSelectedBrandInfo(null);
                }}
                options={updatedCategories}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  const labelA = (optionA?.label || "").toLowerCase();
                  const labelB = (optionB?.label || "").toLowerCase();

                  if (labelA === "tüm kategoriler") return -1;
                  if (labelB === "tüm kategoriler") return 1;

                  return labelA.localeCompare(labelB);
                }}
                suffixIcon={""}
              />
              <Select
                value={selectedBrand}
                notFoundContent={
                  <NotFoundContent message="Marka bulunamadı." />
                }
                className="products__select"
                showSearch
                placeholder="Marka Seçiniz"
                optionFilterProp="children"
                onChange={(_, option) => {
                  setProducts(option ? option.products : []);
                  setSelectedProduct(null);
                  setSelectedBrand(option ? option.value : "tumMarkalar");
                  setSelectedBrandInfo(option ? option.brandMeta : null);
                }}
                options={updatedBrands}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  const labelA = (optionA?.label || "").toLowerCase();
                  const labelB = (optionB?.label || "").toLowerCase();

                  if (labelA === "tüm markalar") return -1;
                  if (labelB === "tüm markalar") return 1;

                  return labelA.localeCompare(labelB);
                }}
                suffixIcon={""}
              />
            </div>
          </div>
          <div className="products__left-content">
            <div className="products__products-container">
              {products
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((product) => (
                  <ProductBox
                    key={product.id}
                    product={product}
                    onClick={() => {
                      handleProductClick();
                      setSelectedProduct((prev) =>
                        prev === product ? null : product
                      );
                      setNewProductClicked(true);
                    }}
                    isSelected={selectedProduct === product}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="products__right-col" ref={productDetailsRef}>
          {selectedProduct ? (
            <Breadcrumb
              separator=<div style={{ color: "var(--text-color01)" }}>/</div>
              items={[
                {
                  title: (
                    <p
                      className="products__breadcrumb-item"
                      onClick={() => {
                        setSelectedCategory("tumKategoriler");
                        setSelectedBrand("tumMarkalar");
                        setSelectedProduct(null);
                        setProducts(allProducts);
                        setBrands(
                          allCategories
                            ?.map((category) => category.brands)
                            .flat()
                        );
                      }}
                    >
                      Tüm Ürünler
                    </p>
                  ),
                },
                {
                  title: (
                    <p
                      className="products__breadcrumb-item"
                      onClick={() => {
                        setSelectedCategory(
                          productBreadcrumbInfo?.productCategory?.name
                        );
                        setSelectedBrand("tumMarkalar");
                        setBrands(
                          productBreadcrumbInfo?.productCategory?.brands
                        );
                        setSelectedProduct(null);
                        setProducts(
                          productBreadcrumbInfo?.productCategory?.brands
                            ?.map((brand) => brand.products)
                            .flat()
                        );
                      }}
                    >
                      {productBreadcrumbInfo?.productCategory?.name}
                    </p>
                  ),
                },
                {
                  title: (
                    <p
                      className="products__breadcrumb-item"
                      onClick={() => {
                        setSelectedCategory(
                          productBreadcrumbInfo?.productCategory?.name
                        );
                        setBrands(
                          productBreadcrumbInfo?.productCategory?.brands
                        );
                        setSelectedBrand(
                          productBreadcrumbInfo?.productBrand?.name
                        );
                        setSelectedProduct(null);
                        setSelectedBrandInfo(
                          productBreadcrumbInfo?.productBrand?.brandMeta
                        );
                        setBreadcrumbBrandClicked(true);
                      }}
                    >
                      {productBreadcrumbInfo?.productBrand?.name}
                    </p>
                  ),
                },
                {
                  title: (
                    <p className="products__breadcrumb-item">
                      {selectedProduct?.name}
                    </p>
                  ),
                },
              ]}
            />
          ) : null}
          {!selectedProduct ? (
            selectedBrand === "tumMarkalar" || !selectedBrandInfo ? (
              <>
                <img
                  src={productsMain}
                  alt="productsMain"
                  className="products__main-img"
                />
                <p className="products__text">
                  Doktor Tarsa’nın performans odaklı ürün yelpazesine göz atın.
                  Ürünlerin teknik özelliklerini ve kullanımları hakkında bilgi
                  sahibi olun. Her seçimle veriminizi maksimize edin. Seçtiğiniz
                  ürünleri hızlıca satın alabilirsiniz. Satın alma işleminiz
                  sonrasında hazırlanan ürünleri, sistemin belirlediği size en
                  yakın bayiden teslim alabilirsiniz.
                </p>
              </>
            ) : (
              <>
                <img
                  className="products__main-img"
                  src={selectedBrandInfo?.imageObj?.original}
                  alt="brand-info"
                />
                <div>
                  <p className="products__brand-title">
                    {selectedBrandInfo?.title}
                  </p>
                  <div
                    className="products__brand-text"
                    dangerouslySetInnerHTML={{
                      __html: selectedBrandInfo?.longDescription,
                    }}
                  />
                </div>
              </>
            )
          ) : (
            <>
              <div className="products__product-image-container">
                <img
                  src={selectedProduct?.imageObj?.original || noImage}
                  alt="product-img"
                  className="products__product-image"
                />
              </div>
              <div className="products__product-info-container">
                <p className="products__product-name">
                  {selectedProduct?.name}
                </p>
                <div
                  className="products__product-text"
                  dangerouslySetInnerHTML={{
                    __html: selectedProduct?.description,
                  }}
                />
              </div>
              <div className="products__product-green-card">
                <div className="products__green-card-bg">
                  <div className="products__green-card-text-container">
                    <div className="products__green-name">
                      {selectedProduct?.name}
                    </div>
                    <div className="products__green-title">
                      <p>Besinler</p>
                      <p>Kg/Da</p>
                    </div>
                  </div>
                  <div className="products__green-table">
                    <div className="products__green-table-column">
                      {renderTableItem(
                        "N",
                        selectedProduct?.ingredients?.N ?? null
                      )}
                      {renderTableItem(
                        "P₂O₅",
                        selectedProduct?.ingredients?.P ?? null
                      )}
                      {renderTableItem(
                        "K₂O",
                        selectedProduct?.ingredients?.K ?? null
                      )}
                      {renderTableItem(
                        "S",
                        selectedProduct?.ingredients?.S ?? null
                      )}
                      {renderTableItem(
                        "CaO",
                        selectedProduct?.ingredients?.CaO ?? null
                      )}
                      {renderTableItem(
                        "MgO",
                        selectedProduct?.ingredients?.MgO ?? null
                      )}
                    </div>
                    <div className="products__green-table-column">
                      {renderTableItem(
                        "Fe",
                        selectedProduct?.ingredients?.Fe ?? null
                      )}
                      {renderTableItem(
                        "Cu",
                        selectedProduct?.ingredients?.Cu ?? null
                      )}
                      {renderTableItem(
                        "Zn",
                        selectedProduct?.ingredients?.Zn ?? null
                      )}
                      {renderTableItem(
                        "Mn",
                        selectedProduct?.ingredients?.Mn ?? null
                      )}
                      {renderTableItem(
                        "Mo",
                        selectedProduct?.ingredients?.Mo ?? null
                      )}
                      {renderTableItem(
                        "B",
                        selectedProduct?.ingredients?.B ?? null
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
