import { Link } from "react-router-dom";
import "./ProgramBox.css";
import noImage from "../assets/noImage.png";

const yieldGoalColors = {
  maximumFertilizers: "#006400",
  highFertilizers: "#32CD32",
  optimumFertilizers: "#FFA500",
};

const yieldGoalName = {
  maximumFertilizers: "Maksimum Verim",
  highFertilizers: "Yüksek Verim",
  optimumFertilizers: "Optimum Verim",
};

export const ProgramBox = ({
  program,
  selectedList,
  programName,
  programImage,
}) => {
  const programId = program?.scheduleRequest?.id;
  const yieldGoal = program?.scheduleRequest?.fertilizer_set;
  const programDuration = program?.scheduleRequest?.duration;
  return (
    <Link
      to={`/programdetail/${programId}`}
      state={{ program }}
      className="programbox__container"
      style={{
        background:
          selectedList === "completedPrograms"
            ? `#767676`
            : yieldGoalColors[yieldGoal],
      }}
    >
      <div className="programbox__inner-container">
        <div className="programbox__image-container">
          <img
            src={programImage || noImage}
            alt="plant-img"
            className="programbox__image"
            style={{
              filter: selectedList === "completedPrograms" && `grayscale(100%)`,
            }}
          />
        </div>
        <p className="programbox__text" style={{ minWidth: 175 }}>
          {programName}
        </p>
        <p className="programbox__text" style={{ minWidth: 175 }}>
          {yieldGoalName[yieldGoal]}
        </p>
        <p className="programbox__text">{programDuration} Ay</p>
      </div>
    </Link>
  );
};
