import "./Header.css";
// import { ButtonBuy } from "../components/ButtonBuy";
import { ButtonProfile } from "../components/ButtonProfile";
import { SearchBar } from "../components/SearchBar";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const Header = ({
  title,
  hideSearchBar,
  hideProfile,
  showBackButton,
  specialBackButton,
}) => {
  return (
    <div className="header__container">
      <div className="header__text-container">
        {specialBackButton ? (
          specialBackButton
        ) : showBackButton ? (
          <button
            className="header__back-button"
            onClick={() => window.history.back()}
          >
            <ArrowLeftOutlined />
          </button>
        ) : null}
        <p className="header__text">
          {title.includes("©") ? (
            <span>
              {title.split("©")[0]}
              <sup>©</sup>
              {title.split("©")[1]}
            </span>
          ) : (
            title
          )}
        </p>
      </div>
      {!hideSearchBar ? <SearchBar /> : null}
      {!hideProfile ? <ButtonProfile /> : null}
      {/* <ButtonBuy /> */}
    </div>
  );
};
