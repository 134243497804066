import "./NewsDetail.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";

export const NewsDetail = ({ selectedNews, setSelectedNews }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (content) => {
    setModalContent(content);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setModalContent(null);
    setIsModalVisible(false);
  };

  const parseHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.querySelectorAll("img");
    return Array.from(images).map((img) => img.src);
  };

  const attachEventListeners = (htmlString) => {
    const images = parseHTML(htmlString);
    images.forEach((imageSrc) => {
      const imageElements = document.querySelectorAll(`img[src="${imageSrc}"]`);
      imageElements.forEach((imageElement) => {
        imageElement.addEventListener("click", () => showModal(imageSrc));
      });
    });
  };

  useEffect(() => {
    attachEventListeners(selectedNews?.content);
  }, [selectedNews?.content]);

  const renderReferences = () => {
    if (selectedNews?.references) {
      const referencesArray = selectedNews.references.split(/\s*\|\s*/); // | seperator
      return referencesArray.map((reference, index) => (
        <div key={index} className="newsdetail__reference-container">
          <a
            href={reference}
            className="newsdetail__reference"
            target="_blank"
            rel="noopener noreferrer"
          >
            {reference}
          </a>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="newsdetail__container-expanded">
      <div className="newsdetail__top-container">
        <div className="newsdetail__title-container">
          <button
            className="newsdetail__button"
            onClick={() => setSelectedNews(null)}
          >
            <ArrowLeftOutlined />
            Geri Dön
          </button>
          <p className="newsdetail__title">{selectedNews?.title}</p>
          <div></div>
        </div>
        <div className="newsdetail__image-container-expanded">
          <img
            src={selectedNews?.image}
            alt="news-img"
            className="newsdetail__image-expanded"
          />
        </div>
      </div>
      <div
        className="newsdetail__content"
        dangerouslySetInnerHTML={{ __html: selectedNews?.content }}
      />
      {selectedNews?.references ? (
        <div className="newsdetail__references-container">
          <p className="newsdetail__references-header">REFERANSLAR</p>
          {renderReferences()}
        </div>
      ) : null}
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        centered
        footer={null}
        width={"fit-content"}
      >
        {modalContent && <img src={modalContent} alt="clicked-img" />}
      </Modal>
    </div>
  );
};
