import "./Sidebar.css";
import { useState, useEffect } from "react";
import { Menu, Switch } from "antd";
import logo from "../assets/DRT_Logo.png";
import menuHome from "../assets/menu_items/menuHome.png";
import menuFertilizing from "../assets/menu_items/menuFertilizing.png";
import menuProducts from "../assets/menu_items/menuProducts.png";
import menuFusion from "../assets/menu_items/menuFusion.png";
import menuHelp from "../assets/menu_items/menuHelp.png";
import { Link } from "react-router-dom";
import { useDarkMode } from "../context/DarkModeContext";
import door from "../assets/door.png";

const items = [
  {
    key: "1",
    icon: (
      <img className="sidebar__menu--icon" src={menuHome} alt="menu-item" />
    ),
    label: "Anasayfa",
    url: "/",
  },
  {
    key: "2",
    icon: (
      <img
        className="sidebar__menu--icon"
        src={menuFertilizing}
        alt="menu-item"
      />
    ),
    label: "Gübreleme Programı",
    url: "/fertilizing",
  },
  {
    key: "3",
    icon: (
      <img className="sidebar__menu--icon" src={menuProducts} alt="menu-item" />
    ),
    label: "Ürünler",
    url: "/products",
  },
  {
    key: "4",
    icon: (
      <img className="sidebar__menu--icon" src={menuFusion} alt="menu-item" />
    ),
    label: "DRT Fusion© Paneli",
    url: "/fusionpanel",
  },
  {
    key: "5",
    icon: (
      <img className="sidebar__menu--icon" src={menuHelp} alt="menu-item" />
    ),
    label: "Yardım & Destek",
    url: "/help",
  },
];

export const Sidebar = () => {
  const { isDarkMode, changeDarkMode } = useDarkMode();
  const [mode, setMode] = useState(isDarkMode ? "dark" : "light");

  useEffect(() => {
    changeDarkMode(mode === "dark");
  }, [mode, changeDarkMode]);

  return (
    <div className="sidebar__container">
      <Menu mode="inline" defaultSelectedKeys={["1"]} className="sidebar__menu">
        <Link style={{ alignSelf: "center" }} to="/">
          <img src={logo} alt="DRT Logo" className="sidebar__menu--logo" />
        </Link>
        {items.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link style={{ fontWeight: 300 }} to={item.url}>
              {item.label.includes("©") ? (
                <span>
                  {item.label.split("©")[0]}
                  <sup>©</sup>
                  {item.label.split("©")[1]}
                </span>
              ) : (
                item.label
              )}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <div className="sidebar__footer">
        <button
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("isLoggedIn");
            window.location.href = "/";
          }}
          className="sidebar__footer-logout-button"
        >
          <img src={door} alt="door" className="sidebar__footer-logout-icon" />
          Çıkış Yap
        </button>
        <div className="sidebar__footer-switch-area">
          <Switch
            style={{ alignSelf: "center", marginBottom: 10 }}
            checked={mode === "dark"}
            onChange={(e) => {
              const _mode = e ? "light" : "dark";
              if (_mode === "dark") {
                document.documentElement.setAttribute("data-theme", "light");
                localStorage.setItem("theme", "light");
                setMode("light");
              } else {
                document.documentElement.setAttribute("data-theme", "dark");
                localStorage.setItem("theme", "dark");
                setMode("dark");
              }
            }}
          />
          <p className="sidebar__footer-switch-text">
            {mode === "dark" ? "Karanlık Mod" : "Aydınlık Mod"}
          </p>
        </div>
        <p className="sidebar__footer--text">Doktor Tarsa Tarım Sanayi</p>
        <p className="sidebar__footer--text">San. ve Tic. A.Ş.</p>
        <p className="sidebar__footer--text">Tüm Hakları Saklıdır. ®</p>
      </div>
    </div>
  );
};
