import "./YieldBox.css";
import { CaretRightFilled } from "@ant-design/icons";

const yieldGoalColors = {
  maximumFertilizers: "#006400",
  highFertilizers: "#32CD32",
  optimumFertilizers: "#FFA500",
  normalFertilizers: "#FFD700",
};

export const YieldBox = ({ yieldGoal, yieldGoalName, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className="yieldbox__container"
      style={{ backgroundColor: yieldGoalColors[yieldGoal] }}
      disabled={disabled}
    >
      <div className="yieldbox__title-container">
        <p className="yieldbox__title">{yieldGoalName}</p>
      </div>
      <div disabled={disabled} onClick={onClick} className="yieldbox__button">
        <CaretRightFilled
          style={{ color: "#387D3B", fontSize: 32, paddingLeft: 5 }}
        />
      </div>
    </button>
  );
};
