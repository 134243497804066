import { Link } from "react-router-dom";
import "./ProgramDetailBox.css";
import noImage from "../assets/noImage.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const ProgramDetailBox = ({ fertilizer }) => {
  return (
    <div className="programdetailbox__container">
      <Link
        className="programdetailbox__image-container"
        to={`/products/`}
        state={{ option: fertilizer.choosenFertilizer }}
      >
        {fertilizer.choosenFertilizer.image ? (
          <img
            src={
              fertilizer.choosenFertilizer?.imageObj?.thumbnail256256 || noImage
            }
            alt="product"
            className="programdetailbox__image"
          />
        ) : (
          <Spin
            indicator={
              <LoadingOutlined style={{ color: "#387d3b", fontSize: 45 }} />
            }
          />
        )}
      </Link>
      <div className="programdetailbox__content">
        <div className="programdetailbox__title-container">
          <p className="programdetailbox__title">
            {fertilizer.choosenFertilizer.name}
          </p>
        </div>
        <div className="programdetailbox__boxes-container">
          <div className="programdetailbox__box">
            <div className="programdetailbox__box-title">Uygulama Sayısı</div>
            <div className="programdetailbox__box-number">4</div>
          </div>
          <div className="programdetailbox__box">
            <div className="programdetailbox__box-title">Haftalık KG/Da</div>
            <div className="programdetailbox__box-number">
              {Number(fertilizer.amountNeeded / 4).toFixed(2)}
            </div>
          </div>
          <div className="programdetailbox__box">
            <div className="programdetailbox__box-title">Aylık Doz(KG/Da)</div>
            <div className="programdetailbox__box-number">
              {Number(fertilizer.amountNeeded).toFixed(2)}
            </div>
          </div>
        </div>
        {fertilizer.choosenFertilizer.id ===
        "812d94bb-294e-4617-8e68-16c98c58b419" ? (
          <div className="programdetailbox__note-container">
            <p className="programdetailbox__note">
              *Diğer gübrelerden ayrı verilecektir.
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
