import { useLocation, Link, useParams } from "react-router-dom";
import "./ProgramDetail.css";
import { useState, useRef, useEffect } from "react";
import { Header } from "../../components/Header";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ProgramDetailBox } from "../../components/ProgramDetailBox";
import { tR } from "../../components/functions";
import { Modal } from "antd";
import { useDarkMode } from "../../context/DarkModeContext";
import { useUser } from "../../context/UserContext";

export const ProgramDetail = () => {
  let { state } = useLocation();
  const { userDetails } = useUser();
  const { isDarkMode } = useDarkMode();
  const { programId } = useParams();
  const programsContainerRef = useRef(null);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [programList, setProgramList] = useState(null);
  const [shownProgram, setShownProgram] = useState(null);
  const programDuration = shownProgram?.scheduleRequest?.duration;
  // const programId = shownProgram?.scheduleRequest?.id;
  const verilecekSonuc = shownProgram?.amountNeeded; // gecici tablo icin
  const [checkUserAlim, setCheckUserAlim] = useState(false);

  useEffect(() => {
    getProgramList();
    checkUsernameForAlim();
  }, []);

  useEffect(() => {
    if (state) {
      setShownProgram(state.program);
    } else {
      getProgramWithId();
    }
  }, [state]);

  const checkUsernameForAlim = () => {
    if (userDetails?.name?.toLowerCase().includes("alim")) {
      setCheckUserAlim(true);
    }
  };

  const getProgramList = async () => {
    const _programList = await tR("/schedule-requests/program", "GET");
    setProgramList(_programList);
  };

  const getProgramWithId = async () => {
    const _program = await tR(`/schedule-requests/single/${programId}`, "GET");
    setShownProgram(_program);
  };

  const getProgramName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return program.name;
    }
  };

  const programName = getProgramName(
    shownProgram?.scheduleRequest?.program_name ||
      shownProgram?.scheduleRequest?.programName // Remove one of them after backend is updated
  );

  const filteredFertilizers = shownProgram?.fertilizingSchedule
    ?.filter((fertilizer) => fertilizer.month === selectedMonth - 1)
    ?.sort((a, b) =>
      a.choosenFertilizer.fertilizerSet === "pureFertilizers" ? 1 : -1
    );

  const handleMonthChange = (month) => {
    setSelectedMonth(month);

    if (programsContainerRef.current) {
      programsContainerRef.current.scrollTop = 0;
    }
  };

  const handleDeleteProgram = async () => {
    await tR(`/schedule-requests/schedule/${programId}`, "DELETE");
    window.history.back();
  };

  return (
    <div className="outer__container">
      <Header
        title="Gübreleme Programı"
        showBackButton
        specialBackButton={
          <Link
            to="/programlist"
            state={{ selectedList: "currentPrograms" }}
            className="header__back-button"
          >
            <ArrowLeftOutlined />
          </Link>
        }
      />
      <div className="programdetail__content">
        <div className="programdetail__left">
          <div className="programdetail__left-content">
            <p className="programdetail__title">Aylar</p>
            <div
              className="programdetail__months-container"
              style={{ height: checkUserAlim ? `50%` : `100%` }}
            >
              {[...Array(programDuration)].map((_, i) => (
                <button
                  key={i + 1}
                  className={`programdetail__month-button ${
                    i + 1 === selectedMonth
                      ? "programdetail__month-button-selected"
                      : ""
                  }`}
                  style={{
                    height: checkUserAlim
                      ? 30
                      : `clamp(2rem, 0rem + 2.5vw, 4rem)`,
                    fontSize: checkUserAlim
                      ? 12
                      : `clamp(12px, 0.5rem + 0.5vw, 18px)`,
                  }}
                  onClick={() => handleMonthChange(i + 1)}
                >{`${i + 1}. Ay`}</button>
              ))}
            </div>
            {verilecekSonuc && checkUserAlim ? (
              <div className="programdetail__gecici-tablo">
                <div style={{ width: "50%", fontSize: 12 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Kg/Da Dönüşüm
                  </p>
                  <p>N: {verilecekSonuc?.kgDa.Nitrogen.toFixed(2)}</p>
                  <p>P2O5: {verilecekSonuc?.kgDa.Phosphorus.toFixed(2)}</p>
                  <p>K2O: {verilecekSonuc?.kgDa.Potassium.toFixed(2)}</p>
                  <p>CaO: {verilecekSonuc?.kgDa.CalciumOxide.toFixed(2)}</p>
                  <p>MgO: {verilecekSonuc?.kgDa.MagnesiumOxide.toFixed(2)}</p>
                  <p>S: {verilecekSonuc?.kgDa.Sulphur.toFixed(2)}</p>
                  <p>P2O5Ph: {verilecekSonuc?.kgDa.P2O5Ph.toFixed(2)}</p>
                </div>
                <div style={{ width: "50%", fontSize: 12 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Verilecek Sonuç
                  </p>
                  <p>N: {verilecekSonuc?.Nitrogen.toFixed(2)}</p>
                  <p>P2O5: {verilecekSonuc?.Phosphorus.toFixed(2)}</p>
                  <p>K2O: {verilecekSonuc?.Potassium.toFixed(2)}</p>
                  <p>CaO: {verilecekSonuc?.CalciumOxide.toFixed(2)}</p>
                  <p>MgO: {verilecekSonuc?.MagnesiumOxide.toFixed(2)}</p>
                  <p>S: {verilecekSonuc?.Sulphur.toFixed(2)}</p>
                  <p>P2O5Ph: {verilecekSonuc?.P2O5Ph.toFixed(2)}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="programdetail__right">
          <div className="programdetail__right-top">
            <p className="programdetail__title">{programName}</p>
            <button
              onClick={() => setDeleteConfirmationVisible(true)}
              className="programdetail__delete-button"
            >
              <DeleteOutlined style={{ fontSize: 20 }} />
            </button>
          </div>
          <div
            ref={programsContainerRef}
            className="programdetail__programs-container"
          >
            {filteredFertilizers &&
              filteredFertilizers.map((fertilizer, index) => (
                <ProgramDetailBox key={index} fertilizer={fertilizer} />
              ))}
          </div>
        </div>
      </div>
      <Modal
        maskClosable={false}
        width={750}
        wrapClassName="programdetail__delete-modal-wrapper"
        className="programdetail__delete-modal"
        centered
        closable={false}
        open={deleteConfirmationVisible}
        onOk={handleDeleteProgram}
        onCancel={() => setDeleteConfirmationVisible(false)}
        footer={
          <div className="programdetail__buttons-container">
            <button
              className="programdetail__delete-modal-button programdetail__delete-modal-button-cancel"
              style={{
                backgroundColor: isDarkMode ? "transparent" : "#d18700",
                color: isDarkMode ? "#d18700" : "#fff",
              }}
              onClick={() => setDeleteConfirmationVisible(false)}
            >
              Geri Dön
            </button>
            <button
              className="programdetail__delete-modal-button programdetail__delete-modal-button-delete"
              style={{
                backgroundColor: isDarkMode ? "transparent" : "#df362d",
                color: isDarkMode ? "#df362d" : "#fff",
              }}
              onClick={handleDeleteProgram}
            >
              Programı Sil
            </button>
          </div>
        }
      >
        <p className="programdetail__modal-text">
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <div>
              <WarningOutlined style={{ fontSize: 64, marginRight: 10 }} />
            </div>
            <div>
              {programName} isimli programı silmek istediğinize emin misiniz?
            </div>
          </div>
          <br />
          Bu işlem geri alınamaz.
        </p>
        <br />
      </Modal>
    </div>
  );
};
