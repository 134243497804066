import "./Fertilizing.css";
import { Header } from "../../components/Header";
import gubrelemeCiftci from "../../assets/gubrelemeCiftci.png";
import DRT_Logo from "../../assets/DRT_Logo.png";
import { Link } from "react-router-dom";

export const Fertilizing = () => {
  return (
    <div className="outer__container">
      <Header title="Gübreleme Programı" />
      <div className="fertilizing__content">
        <div className="fertilizing__left-col">
          <div className="fertilizing__logo-container">
            <img src={DRT_Logo} alt="logo" className="fertilizing__logo" />
          </div>
          <div className="fertilizing__buttons-container">
            <Link className="fertilizing__button" to="/createnewprogram">
              Yeni Program Oluştur
            </Link>
            <Link
              state={{ selectedList: "currentPrograms" }}
              className="fertilizing__button"
              to="/programlist"
            >
              Mevcut Programlar
            </Link>
            <Link
              state={{ selectedList: "completedPrograms" }}
              className="fertilizing__button"
              to="/programlist"
            >
              Tamamlanan Programlar
            </Link>
          </div>
        </div>
        <div className="fertilizing__right-col">
          <img
            src={gubrelemeCiftci}
            alt="ciftci"
            className="fertilizing__farmer"
          />
          <div className="fertilizing__text-area">
            <p className="fertilizing__title">
              Doktor Tarsa {window.innerWidth > 1300 && <br />} Gübreleme
              Programı
            </p>
            <p className="fertilizing__text">
              Bu sayfada, toprağınızın ve bitkilerinizin spesifik ihtiyaçlarına
              göre özelleştirilmiş bir gübreleme programı oluşturabileceksiniz.
              Başlamadan önce, lütfen toprak analiz sonuçlarınızı yanınızda
              bulundurun. Eğer analiz sonucunuz yoksa sorun değil. Önerilen
              program, sizin için en uygun gübreleme stratejisini belirlemenize
              yardımcı olacaktır.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
