import "./LoginStore.css";
import { Form, Input } from "antd";
import logo from "../../assets/DRT_Logo.png";
import { Link } from "react-router-dom";

export const LoginStore = () => {
  const onFinish = (values) =>
    console.error("Received values of form: ", values);

  return (
    <div className="loginStore__container">
      <div className="loginStore__container02">
        <div className="loginStore__green-bar"></div>
        <div className="loginStore__content">
          <div></div> {/* for justify-content */}
          <div className="loginStore__black--logo-container">
            <img
              src={logo}
              alt="drt-logo"
              className="loginStore__black--logo"
            />
          </div>
          <div className="loginStore__black--login-form-container">
            <Form
              name="normal_login"
              className="loginStore__black--login-form"
              onFinish={onFinish}
            >
              <Form.Item
                className="loginStore__black--form-item"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Lütfen cep telefonunuzu giriniz.",
                  },
                ]}
              >
                <Input
                  className="loginStore__black--form-input"
                  placeholder="Cep Telefonu"
                />
              </Form.Item>
              <Form.Item
                className="loginStore__black--form-item"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şifrenizi giriniz.",
                  },
                ]}
              >
                <Input
                  className="loginStore__black--form-input"
                  type="password"
                  placeholder="Şifreniz"
                />
              </Form.Item>
              <Form.Item>
                {/* <Link to="/"> */}
                <button
                  className="loginStore__black--form-login-button"
                  htmlType="submit"
                >
                  Giriş Yap
                </button>
                {/* </Link> */}
              </Form.Item>
            </Form>
          </div>
          <div className="loginStore__black--login-user-button-container">
            <Link to="/">
              <button className="loginStore__black--login-user-button">
                Kullanıcı Girişi
              </button>
            </Link>
          </div>
          <div className="loginStore__black--footer">
            <p className="loginStore__black--footer-text">
              Copyright © Doktor Tarsa Tüm hakları saklıdır.
            </p>
            <p className="loginStore__black--footer-text">v1.00</p>
          </div>
          <div></div> {/* for justify-content */}
        </div>
        <div
          className="loginStore__green-bar"
          style={{ backgroundColor: "#387D3B" }}
        ></div>
      </div>
    </div>
  );
};
