import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LoginUser } from "./pages/LoginSignup/LoginUser";
import { Signup } from "./pages/LoginSignup/Signup";
import { LoginStore } from "./pages/LoginSignup/LoginStore";
import { Sidebar } from "./components/Sidebar";
import { Home } from "./pages/Home";
import { Fertilizing } from "./pages/Fertilizing/Fertilizing";
import { Products } from "./pages/Products";
import { FusionPanel } from "./pages/FusionPanel";
import { Settings } from "./pages/Settings";
import { Help } from "./pages/Help";
import { Profile } from "./pages/Profile";
import { ProgramList } from "./pages/Fertilizing/ProgramList";
import { CreateNewProgram } from "./pages/Fertilizing/CreateNewProgram";
import { ProgramDetail } from "./pages/Fertilizing/ProgramDetail";
import axios from "axios";
import { tR, url } from "./components/functions";
import { useUser } from "./context/UserContext";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUserDetails } = useUser();

  const getUserDetails = async () => {
    const _userDetails = await tR("/users/me/details", "GET");
    setUserDetails(_userDetails);
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUserDetails();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token && refreshToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = async ({ loginPhone }) => {
    let mobile = loginPhone;

    const smsCheck = await tR("/auth/login", "POST", { mobile: mobile });
    console.log(smsCheck);
    if (smsCheck) {
      return smsCheck;
    }
    //localStorage.setItem("token", response.data.accessToken);
    //localStorage.setItem("refreshToken", response.data.refreshToken);
    //setIsLoggedIn(true);
  };

  const handleMfa = async ({ code, mobile, Session }) => {
    const endpoint = url + "/auth/sms-mfa";
    const data = {
      mobile: mobile,
      code: code,
      Session: Session,
    };
    const response = await axios.post(endpoint, data);
    console.log(response);

    if (response.data.accessToken) {
      localStorage.setItem("token", response.data.accessToken.jwtToken);
      localStorage.setItem("refreshToken", response.data.refreshToken.token);
      setIsLoggedIn(true);
    }
  };

  return (
    <div className="App">
      <Router>
        {isLoggedIn && <Sidebar />}
        <Routes>
          {isLoggedIn ? (
            <Route exact path="/" element={<Home />} />
          ) : (
            <Route
              exact
              path="/"
              element={
                <LoginUser
                  handleLogin={handleLogin}
                  handleMfa={handleMfa}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
          )}
          <Route exact path="/loginstore" element={<LoginStore />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/fertilizing" element={<Fertilizing />} />
          <Route
            exact
            path="/createnewprogram"
            element={<CreateNewProgram />}
          />
          <Route exact path="/programlist" element={<ProgramList />} />
          <Route
            exact
            path="/programdetail/:programId"
            element={<ProgramDetail />}
          />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/fusionpanel" element={<FusionPanel />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/help" element={<Help />} />
          <Route exact path="/profile" element={<Profile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
