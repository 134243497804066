import "./Help.css";
import { Header } from "../components/Header";
import DRT_Logo from "../assets/DRT_Logo.png";
import telephone from "../assets/telephone.png";
import mail from "../assets/mail.png";
import { Select, Collapse } from "antd";
import { NotFoundContent } from "../components/NotFoundContent";
import { useEffect, useState } from "react";
import { PopUpMessage } from "../components/PopUpMessage";
import { tR } from "../components/functions";

const dummyFAQ = [
  {
    key: 1,
    className: "help__middle-faq-accordion-item",
    label: "Doktor Tarsa'nın uzmanlık alanları nelerdir?",
    children:
      "Doktor Tarsa, tarım sektöründe uzmanlaşmış olup, özellikle bitki besleme ve toprak verimliliği konularında hizmet vermektedir. Gübreleme, toprak analizi, bitki besleme programları gibi konularda uzmanlığımız bulunmaktadır.",
  },
  {
    key: 2,
    className: "help__middle-faq-accordion-item",
    label: "Hangi tarım ürünleri için  bitki besleme ürünü satın alabilirim?",
    children:
      "Doktor Tarsa, çeşitli tarım ürünleri için gübreleme hizmeti sunmaktadır. Buğday, mısır, pamuk, sebze ve meyve gibi birçok tarım ürünü için özel gübreleme programları ve ürünleri geliştirmekteyiz.",
  },
  {
    key: 3,
    className: "help__middle-faq-accordion-item",
    label: "Firmanızın bitki besleme ürünleri çevre dostu mu?",
    children:
      "Evet, Doktor Tarsa olarak çevreye duyarlı bir yaklaşıma sahibiz. Ürettiğimiz gübreler, çevre dostu formülasyonlar kullanılarak geliştirilmiştir ve toprak sağlığını desteklemek amacıyla tasarlanmıştır.",
  },
  {
    key: 4,
    className: "help__middle-faq-accordion-item",
    label: "Toprak analizi nedir ve neden önemlidir?",
    children:
      "Toprak analizi, toprağın besin içeriğini belirlemek amacıyla yapılan bir testtir. Doktor Tarsa olarak, müşterilerimize en iyi hizmeti sunabilmek için toprak analizi yapmaktayız. Bu, doğru gübreleme programlarını oluşturabilmemiz ve toprak verimliliğini artırmamız için önemlidir.",
  },
];

export const Help = () => {
  const [allTopics, setAllTopics] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = async () => {
    const _allTopics = await tR(`/messages/get/topics`, "GET");
    setAllTopics(_allTopics);
  };

  const handleMessageSuccess = async () => {
    await tR(`/messages`, "POST", { title, content });
    PopUpMessage({ type: "success", content: "Mesajınız gönderildi!" });
    setTitle(null);
    setContent("");
  };

  return (
    <div className="outer__container">
      <Header title="Yardım & Destek" />
      <div className="help__content">
        <div className="help__left">
          <div className="help__logo-container">
            <img src={DRT_Logo} alt="logo" className="help__logo" />
          </div>
          <div className="help__white-container">
            <p className="help__white-header">
              Doktor Tarsa Tarım Sanayi ve Ticaret A.Ş.
            </p>
            <p className="help__white-text">
              Antalya Organize Sanayi Bölgesi İkinci Kısım 22.Cadde No.10 07190
              Döşemealtı Antalya
            </p>
            <p className="help__white-text">0(242)249 46 46</p>
            <p className="help__white-text">bilgi@drt.com.tr</p>
            <p className="help__white-text">VKN: 309 003 8515</p>
          </div>
        </div>
        <div className="help__middle">
          <p className="help__title">Sıkça Sorulan Sorular</p>
          <div className="help__middle-faq">
            <Collapse
              accordion
              items={dummyFAQ}
              className="help__middle-faq-accordion"
            />
          </div>
        </div>
        <div className="help__right">
          <p className="help__title">İletişim</p>
          <div className="help__right-top">
            <Select
              value={title}
              notFoundContent={
                <NotFoundContent message="Mahalle bulunamadı." />
              }
              showSearch
              placeholder="Konu Başlığı"
              className="help__select"
              optionFilterProp="children"
              onChange={(value, option) => setTitle(value)}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={allTopics?.map((item) => ({
                value: item,
                label: item,
              }))}
              suffixIcon={""}
            />
            <textarea
              className="help__right-textarea"
              placeholder="Mesajınız"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <button
              className="help__right-button"
              onClick={handleMessageSuccess}
              disabled={!title || !content || title === "" || content === ""}
            >
              Gönder
            </button>
          </div>
          <div className="help__right-bottom">
            <div className="help__right-bottom-item">
              <img
                src={telephone}
                alt="telephone"
                className="help__right-bottom-logo"
              />
              <p className="help__right-bottom-text">0(242) 249 46 46</p>
            </div>
            <a
              href="mailto:bilgi@drt.com.tr"
              className="help__right-bottom-item help__right-bottom-item-mail"
            >
              <img src={mail} alt="mail" className="help__right-bottom-logo" />
              <p className="help__right-bottom-text">bilgi@drt.com.tr</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
