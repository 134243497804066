import { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapboxSearchBox } from "@mapbox/search-js-web";

/* eslint import/no-webpack-loader-syntax: off */

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const mapBoxAccessToken =
  "pk.eyJ1IjoibWlrcm90ZXJyYSIsImEiOiJjbHFhcTZwOGgxcHdhMnJtOWlyYnh6MWMzIn0.urTAkzs1FXc7nXOgmYJbZg";

var map;

const Map = ({ editMap, markers, onMarkerUpdate }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;

    map = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [markers[0].longitude, markers[0].latitude],
      zoom: 14,
    });

    if (editMap) {
      const search = new MapboxSearchBox();
      search.accessToken = mapBoxAccessToken;
      search.placeholder = "Ara";
      map.addControl(search);
    }

    return () => {
      map.remove();
    };
  }, [markers, editMap]);

  useEffect(() => {
    if (editMap) {
      map.on("click", (e) => {
        const newLatitude = e.lngLat.lat;
        const newLongitude = e.lngLat.lng;

        const newMarkers = [{ longitude: newLongitude, latitude: newLatitude }];
        onMarkerUpdate(newMarkers);
      });
    }
  }, [markers, editMap, onMarkerUpdate]);

  useEffect(() => {
    if (markers) {
      markers.forEach((marker) => {
        const el = document.createElement("div");
        el.className = "marker";
        el.style.width = "32px";
        el.style.height = "32px";
        const img = document.createElement("img");
        img.src = "https://mktrcdn.s3.us-east-1.amazonaws.com/appIcons/gps.svg";
        img.style.width = "32px";
        img.style.height = "32px";
        img.style.marginTop = "-25px";
        el.appendChild(img);

        new mapboxgl.Marker(el)
          .setLngLat([marker.longitude, marker.latitude])
          .addTo(map);
      });
    }
  }, [markers]);

  return <div ref={mapContainerRef} className="profile__map-content" />;
};

export default Map;
