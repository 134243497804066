import axios from "axios";
export const url = "https://server11.mikroterratechnologies.com:5000";
// export const url = "http://192.168.1.104:5000";

export async function tR(endpoint, method, data) {
  try {
    const res = await axios({
      method: method,
      url: url + endpoint,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      timeout: 90000,
    });
    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.error("Token expired");
      await refreshAccessToken();

      return tR(endpoint, method, data);
    } else {
      console.error(`Unhandled error: `, err);
    }
  }
}

async function refreshAccessToken() {
  try {
    const res = await axios.post(url + "/auth/refresh-token", {
      refreshToken: localStorage.getItem("refreshToken"),
    });

    localStorage.setItem("token", res.data.accessToken);
    localStorage.setItem("refreshToken", res.data.refreshToken);

    console.error(`Token refreshed:`, res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.error("Refresh token expired");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    } else {
      console.error(`Unhandled error: `, err);
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    }
  }
}

export function ShortenText(text, maxLength) {
  if (text?.length > maxLength) {
    if (text[maxLength - 1] === " ") {
      return text.substring(0, maxLength - 1) + "...";
    } else {
      return text.substring(0, maxLength) + "...";
    }
  }
  return text;
}

export const weatherIcons = {
  "01d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/008-sun.svg", //	clear sky
  "02d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/004-rain.svg", //	few clouds
  "03d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/017-cloudy.svg", //	scattered clouds
  "04d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/018-cloudy-1.svg", //	broken clouds
  "09d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/028-rain-2.svg", //	shower rain
  "10d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/013-raining.svg", //	rain
  "11d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/018-cloudy-1.svg", //	thunderstorm
  "13d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/038-wind-3.svg", //	snow
  "50d": "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/026-thunder-3.svg", //	mist
  init: "https://mktrcdn.s3.us-east-1.amazonaws.com/weather/008-sun.svg",
};
