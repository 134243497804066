import "./Settings.css";
import { Header } from "../components/Header";

export const Settings = () => {
  return (
    <div className="outer__container">
      <Header title="Ayarlar" />
    </div>
  );
};
