import { Link, useLocation, useParams } from "react-router-dom";
import "./ProgramList.css";
import { useState, useEffect, useRef } from "react";
import { Header } from "../../components/Header";
import DRT_Logo from "../../assets/DRT_Logo.png";
import { ProgramBox } from "../../components/ProgramBox";
import { tR } from "../../components/functions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const ProgramList = () => {
  let { state } = useLocation();
  const { programId } = useParams();
  const programsContainerRef = useRef(null);
  const [selectedList, setSelectedList] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [programList, setProgramList] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state) {
      setSelectedList(state.selectedList);
    }
  }, [state]);

  useEffect(() => {
    getProgramList();
  }, []);

  useEffect(() => {
    const containerRef = programsContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef) {
        containerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [programsContainerRef]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedList]);

  useEffect(() => {
    if (selectedList === "currentPrograms") {
      fetchPrograms("currentprograms");
    } else if (selectedList === "completedPrograms") {
      fetchPrograms("completedprograms");
    }
  }, [selectedList, pageNumber]);

  const fetchPrograms = async (endpoint) => {
    try {
      setIsLoading(true);
      const newPrograms = await tR(
        `/schedule-requests/${endpoint}?page=${pageNumber}`,
        "GET"
      );
      setSelectedPrograms((prevPrograms) =>
        pageNumber === 1 ? newPrograms : [...prevPrograms, ...newPrograms]
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching new programs:", error);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    const container = programsContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const getProgramList = async () => {
    const _programList = await tR("/schedule-requests/program", "GET");
    setProgramList(_programList);
  };

  const getProgramName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return program.name;
    }
  };

  const getProgramImage = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return plant.image;
    }
  };

  return (
    <div className="outer__container">
      <Header title="Gübreleme Programı" />
      <div className="programlist__content">
        <div className="programlist__left-col">
          <div className="programlist__logo-container">
            <img src={DRT_Logo} alt="logo" className="programlist__logo" />
          </div>
          <div className="programlist__buttos-container">
            <Link className="programlist__button" to="/createnewprogram">
              Yeni Program Oluştur
            </Link>
            <Link
              onClick={() => setSelectedList("currentPrograms")}
              className={`programlist__button ${
                selectedList === "currentPrograms"
                  ? "programlist__selected-button"
                  : ""
              }`}
              to="/programlist"
            >
              Mevcut Programlar
            </Link>
            <Link
              onClick={() => setSelectedList("completedPrograms")}
              className={`programlist__button ${
                selectedList === "completedPrograms"
                  ? "programlist__selected-button"
                  : ""
              }`}
              to="/programlist"
            >
              Tamamlanan Programlar
            </Link>
          </div>
        </div>
        <div className="programlist__right-col">
          <p className="programlist__programs-title">
            {selectedList === "currentPrograms"
              ? "Mevcut Programlar"
              : "Tamamlanan Programlar"}
          </p>
          <div
            className="programlist__programs-container"
            ref={programsContainerRef}
          >
            {selectedPrograms.map((program) => (
              <ProgramBox
                selectedList={selectedList}
                key={program.scheduleRequest.id}
                program={program}
                programName={getProgramName(
                  program.scheduleRequest.program_name
                )}
                programImage={getProgramImage(
                  program.scheduleRequest.program_name
                )}
              />
            ))}
            {isLoading && (
              <div className="programlist__loading-spinner">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ color: "#387d3b", fontSize: 45 }}
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
