import "./Signup.css";
import { Form, Checkbox, Row, Col } from "antd";
import drt_id from "../../assets/DRT_ID.png";
import logo from "../../assets/DRT_Logo.png";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import tr from "react-phone-input-2/lang/tr.json";

export const Signup = () => {
  const onFinish = (values) =>
    console.error("Received values of form: ", values);

  return (
    <div className="signup__container">
      <div className="signup__black">
        <div className="signup__black--logo-container">
          <Link className="signup__round-button-container" to="/">
            <button className="signup__round-button">
              <ArrowLeftOutlined
                style={{ color: "#2DBB54", fontSize: "20px" }}
              />
            </button>
          </Link>
          <img src={logo} alt="drt-logo" className="signup__black--logo" />
          <div className="signup__round-button-container"></div>
        </div>
        <div className="signup__black--login-form-container">
          <Form onFinish={onFinish}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="name"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="İsim*"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="surname"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="Soyisim*"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item"
                  name="phoneNumber"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <PhoneInput
                    localization={tr}
                    enableSearch
                    disableSearchIcon
                    containerClass="signup__black--form-phone-input"
                    buttonStyle={{
                      borderTopLeftRadius: 30,
                      borderBottomLeftRadius: 30,
                      border: "none",
                      height: "100%",
                    }}
                    inputStyle={{ border: "none", marginLeft: 10 }}
                    searchStyle={{
                      border: "none",
                      height: "100%",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    searchPlaceholder="Ara..."
                    searchNotFound="Ülke bulunamadı."
                    country={"tr"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item"
                  name="email"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="E-Posta*"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item"
                  name="companyName"
                  rules={[{ message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="İşletme Adı"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item"
                  name="address"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <textarea
                    style={{
                      height: "85px",
                      textIndent: "0px",
                      padding: "7px 20px",
                      borderRadius: "30px",
                    }}
                    className="signup__black--form-input"
                    placeholder="Adresiniz*"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="city"
                  rules={[{ message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="İl"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="district"
                  rules={[{ message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="İlçe"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="sokak"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="Sokak"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="signup__black--form-item"
                  name="no"
                  rules={[{ required: true, message: "Bu alan zorunludur." }]}
                >
                  <input
                    className="signup__black--form-input"
                    placeholder="No."
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item-checkbox"
                  name="userAgreement"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: "Bu alan zorunludur.",
                    },
                  ]}
                >
                  <Checkbox className="signup__black--form-checkbox">
                    <a href="#!">Kullanıcı Sözleşmesini</a> Onaylıyorum
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item-checkbox"
                  name="kvkk"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: "Bu alan zorunludur.",
                    },
                  ]}
                >
                  <Checkbox className="signup__black--form-checkbox">
                    <a href="#!">KVKK Sözleşmesini</a> Onaylıyorum
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  className="signup__black--form-item-checkbox"
                  name="newsletter"
                  valuePropName="checked"
                >
                  <Checkbox className="signup__black--form-checkbox">
                    Duyurulardan Haberdar Olmak İstiyorum
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Form.Item>
                <button
                  htmlType="submit"
                  className="signup__black--signup-button"
                >
                  DRT ID Oluştur
                </button>
              </Form.Item>
            </Row>
          </Form>
        </div>
        <div className="signup__black--footer">
          <p className="signup__black--footer-text">
            Copyright © Doktor Tarsa Tüm hakları saklıdır.
          </p>
          <p className="signup__black--footer-text">v1.00</p>
        </div>
      </div>
      <div className="signup__green">
        <div className="signup__green--image-container">
          <img className="signup__green--image" src={drt_id} alt="logo" />
        </div>
        <div className="signup__green--text-container">
          <p className="signup__green-header">Her şey için tek bir kimlik!</p>
          <p className="signup__green-text">
            DRT ID, Doktor Tarsa’nın tarım ve teknoloji dünyasında sunduğu
            yenilikçi bir kimlik doğrulama sistemidir.
            <br /> <br />
            DRT ID, Doktor Tarsa’nın DRT Connect web ve mobil platformlarına
            kolayca giriş yapma ve DRT gübre otomasyon makinesini yönetme imkanı
            sunar.
            <br /> <br />
            Artık tek bir kimlikle web, mobil ve otomasyon erişimine sahip
            olabilir, güvenli ve kolay bir deneyim yaşayabilirsiniz.
            <br /> <br />
            Doktor Tarsa, “her şey için tek bir kimlik” anlayışıyla tarımı ve
            teknolojiyi bir araya getiriyor, size modern ve verimli bir tarım
            deneyimi sunuyor.
          </p>
        </div>
      </div>
    </div>
  );
};
