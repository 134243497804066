import "./Profile.css";
import { Header } from "../components/Header";
import menuFusion from "../assets/menu_items/menuFusion.png";
import drt_id_image from "../assets/DRT_ID.png";
import Dropzone from "react-dropzone";
import { useEffect, useState } from "react";
import { Spin, Select } from "antd";
import { tR } from "../components/functions";
import { LoadingOutlined } from "@ant-design/icons";
import { useUser } from "../context/UserContext";
import avatar from "../assets/avatar.png";
import { NotFoundContent } from "../components/NotFoundContent";
import {
  getCityNameAndPlates,
  getDistrictsName,
  getNeighbourhoods,
} from "turkey-yl-district";
import Map from "../components/Map";

const dummyInfo = {
  enYakinBayi: {
    name: "Tuncayoğlu Tarım",
    location: "Atatürk Caddesi, 1701 Sokak Dalaman Antalya",
    phone: "0242 444 87 87",
  },
  model: "DRT Fusion Max",
  cihazKimlikNo: "876543211234UY67",
  versiyonNo: "Fusion1",
};

export const Profile = () => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showUploadingLoader, setShowUploadingLoader] = useState(false);
  const { userDetails, setUserDetails } = useUser();
  const [file, setFile] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [editMap, setEditMap] = useState(false);
  const [markers, setMarkers] = useState([
    { longitude: userDetails?.Longitude, latitude: userDetails?.Latitude },
  ]);
  const [latLong, setLatLong] = useState({
    latitude: +userDetails?.Latitude,
    longitude: +userDetails?.Longitude,
  });

  const [cityNames, setCityNames] = useState([]);
  const [districtNames, setDistrictNames] = useState([]);
  const [neighborhoodNames, setNeighborhoodNames] = useState([]);

  const [newCity, setNewCity] = useState(null);
  const [newDistrict, setNewDistrict] = useState(null);
  const [newNeighborhood, setNewNeighborhood] = useState(null);
  const [newStreet, setNewStreet] = useState(null);
  const [newBuilding, setNewBuilding] = useState(null);
  const [newEmail, setNewEmail] = useState(null);

  useEffect(() => {
    if (file) {
      handlePhotoSubmit();
    }
  }, [file]);

  const handlePhotoSubmit = async () => {
    setShowUploadingLoader(true);
    const formData = new FormData();
    formData.append("image", file);

    try {
      const uploadedImage = await tR(
        "/users/upload-profile-image",
        "POST",
        formData
      );

      updateUser({
        ...userDetails,
        profileImage: uploadedImage.original.Location,
      });
      setShowUploadingLoader(false);
    } catch (error) {
      setShowUploadingLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const _cityNames = await getCityNameAndPlates();
      setCityNames(_cityNames);

      const _districtNames = await getDistrictsName(
        newCity || userDetails?.City
      );
      setDistrictNames(_districtNames.Districts);

      const _neighborhoodNames = await getNeighbourhoods(
        newCity || userDetails?.City,
        newDistrict || userDetails?.District
      );
      setNeighborhoodNames(_neighborhoodNames);
    };

    fetchData();
  }, [newCity, newDistrict, editMode]);

  const updateUser = (details) => setUserDetails(details);

  const updateUserInfo = async () => {
    const submitInfo = {
      City: newCity,
      District: newDistrict,
      Province: newNeighborhood,
      Street: newStreet,
      Building: newBuilding,
      email: newEmail,
    };

    Object.keys(submitInfo).forEach(
      (key) => submitInfo[key] === null && delete submitInfo[key]
    );

    await tR(`/users/me/changeDetails`, "PATCH", submitInfo);

    updateUser({ ...userDetails, ...submitInfo });
  };

  const handleMarkerUpdate = (newMarkers) => setMarkers(newMarkers);

  const handleMapUpdate = async () => {
    const submitInfo = {
      Latitude: markers[0].latitude,
      Longitude: markers[0].longitude,
    };

    await tR(`/users/${userDetails?.id}`, "PUT", submitInfo);
    updateUser({ ...userDetails, ...submitInfo });
    setEditMap(false);
  };

  useEffect(() => {
    setMarkers([
      { longitude: userDetails?.Longitude, latitude: userDetails?.Latitude },
    ]);
    setLatLong({
      latitude: +userDetails?.Latitude,
      longitude: +userDetails?.Longitude,
    });
  }, [userDetails?.Longitude, userDetails?.Latitude]);

  return (
    <div className="outer__container">
      <Header title="DRT ID" hideProfile hideSearchBar showBackButton />
      <div className="profile__content">
        <div className="profile__info">
          <div className="profile__drt-id-container">
            <img src={drt_id_image} alt="drt-id" className="profile__drt-id" />
          </div>
          <div
            className="profile__image-container"
            onMouseEnter={() => setShowUploadImage(true)}
            onMouseLeave={() => setShowUploadImage(false)}
          >
            {userDetails?.profileImage ? (
              <img
                src={userDetails?.profileImage}
                alt="profile"
                className="profile__image"
              />
            ) : (
              <img src={avatar} alt="buy" className="profile__image" />
            )}
            <div
              style={{ display: showUploadImage ? "flex" : "none" }}
              className="profile__image-dropzone-container"
            >
              <Dropzone
                onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                multiple={false}
                maxSize={800000000}
                accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="profile__image-dropzone">
                    <input {...getInputProps()} />
                    <p className="profile__image-dropzone-text">
                      Bir resim sürükleyin veya dosya seçmek için tıklayın
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
            <div
              style={{ display: showUploadingLoader ? "flex" : "none" }}
              className="profile__image-dropzone-spinner"
            >
              <Spin
                indicator={
                  <LoadingOutlined style={{ color: "#387d3b", fontSize: 45 }} />
                }
              />
            </div>
          </div>
          <div className="profile__info-text-container">
            <p className="profile__info--text">{userDetails?.name}</p>
            <p className="profile__info--text">{userDetails?.surname}</p>
          </div>
          <div className="profile__info--gray-content">
            <div className="profile__input-container">
              <Select
                popupClassName="profile__select-popup"
                disabled={!editMode}
                value={newCity || userDetails?.City || "Şehir Seçiniz"}
                notFoundContent={
                  <NotFoundContent message="Şehir bulunamadı." />
                }
                className="profile__select"
                showSearch
                placeholder="Şehir Seçiniz"
                optionFilterProp="children"
                onChange={(value, option) => {
                  setNewCity(value);
                  setNewDistrict("İlçe Seçiniz");
                  setNewNeighborhood("Mahalle Seçiniz");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={cityNames?.map((item) => ({
                  value: item.CityName,
                  label: item.CityName,
                }))}
                suffixIcon={""}
              />
              <Select
                popupClassName="profile__select-popup"
                disabled={!editMode}
                value={newDistrict || userDetails?.District || "İlçe Seçiniz"}
                notFoundContent={<NotFoundContent message="İlçe bulunamadı." />}
                className="profile__select"
                showSearch
                placeholder="İlçe Seçiniz"
                optionFilterProp="children"
                onChange={(value, option) => {
                  setNewDistrict(value);
                  setNewNeighborhood("Mahalle Seçiniz");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={districtNames?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                suffixIcon={""}
              />
              <Select
                popupClassName="profile__select-popup"
                disabled={!editMode}
                value={
                  newNeighborhood || userDetails?.Province || "Mahalle Seçiniz"
                }
                notFoundContent={
                  <NotFoundContent message="Mahalle bulunamadı." />
                }
                className="profile__select"
                showSearch
                placeholder="Mahalle Seçiniz"
                optionFilterProp="children"
                onChange={(value, option) => {
                  setNewNeighborhood(value);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={neighborhoodNames?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                suffixIcon={""}
              />
              <div className="profile__input-double">
                <input
                  disabled={!editMode}
                  className="profile__input"
                  placeholder="Sokak/Cadde"
                  defaultValue={userDetails?.Street}
                  onChange={(e) => setNewStreet(e.target.value)}
                />
                <input
                  disabled={!editMode}
                  className="profile__input"
                  placeholder="No"
                  defaultValue={userDetails?.Building}
                  onChange={(e) => setNewBuilding(e.target.value)}
                />
              </div>
              <input
                disabled
                className="profile__input"
                placeholder="Telefon"
                defaultValue={userDetails?.mobile}
              />
              <input
                disabled={!editMode}
                className="profile__input"
                placeholder="E-Posta"
                defaultValue={userDetails?.email}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </div>
            <button
              className="profile__edit-button"
              onClick={() => {
                if (
                  editMode &&
                  newCity &&
                  (!newDistrict ||
                    !newNeighborhood ||
                    newNeighborhood.includes("Seçiniz"))
                ) {
                  console.error(
                    "Please select valid district and neighborhood"
                  );
                } else if (editMode) {
                  updateUserInfo();
                }
                setEditMode(!editMode);
              }}
              disabled={
                editMode &&
                ((newCity &&
                  (!newDistrict ||
                    !newNeighborhood ||
                    newNeighborhood.includes("Seçiniz"))) ||
                  (newDistrict &&
                    (!newNeighborhood || newNeighborhood.includes("Seçiniz"))))
              }
            >
              {editMode ? "Kaydet" : "Düzenle"}
            </button>
          </div>
        </div>
        <div className="profile__map">
          <Map
            editMap={editMap}
            markers={markers}
            onMarkerUpdate={handleMarkerUpdate}
          />
          <p className="profile__title profile__map-title">Arazi Konumu</p>
          <div className="profile__map-info">
            <div></div>
            <div className="profile__latlong-container">
              <div>
                <p className="profile__map-text">Enlem:</p>
                <p className="profile__map-text">Boylam:</p>
              </div>
              <div>
                <p className="profile__map-text">
                  {latLong?.latitude.toFixed(6)}
                </p>
                <p className="profile__map-text">
                  {latLong?.longitude.toFixed(6)}
                </p>
              </div>
            </div>
            <button
              onClick={editMap ? handleMapUpdate : () => setEditMap(true)}
              className="profile__edit-button"
            >
              {editMap ? "Kaydet" : "Düzenle"}
            </button>
          </div>
        </div>
        <div className="profile__right">
          <div className="profile__fusion">
            <div className="profile__fusion--first-row">
              <p className="profile__fusion-title">
                DRT Fusion<sup>©</sup>
              </p>
              <div className="profile__fusion--icon-container">
                <img
                  src={menuFusion}
                  alt="fusion"
                  className="profile__fusion-icon"
                />
              </div>
            </div>
            <div className="profile__fusion--info">
              <p className="profile__fusion--title">Model:</p>
              <p className="profile__fusion--text">{dummyInfo.model}</p>
            </div>
            <div className="profile__fusion--info">
              <p className="profile__fusion--title">Cihaz Kimlik No:</p>
              <p className="profile__fusion--text">{dummyInfo.cihazKimlikNo}</p>
            </div>
            <div className="profile__fusion--info">
              <p className="profile__fusion--title">Versiyon No: </p>
              <p className="profile__fusion--text">{dummyInfo.versiyonNo}</p>
            </div>
          </div>
          <div className="profile__store">
            <p className="profile__title">Yakındaki Bayi</p>
            <div className="profile__store-info">
              <p className="profile__store-text">
                {dummyInfo.enYakinBayi.name}
              </p>
              <p className="profile__store-text">
                {dummyInfo.enYakinBayi.location}
              </p>
              <p className="profile__store-text">
                {dummyInfo.enYakinBayi.phone}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
