import "./ButtonProfile.css";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";
import avatar from "../assets/avatar.png";

export const ButtonProfile = () => {
  const { userDetails } = useUser();

  return (
    <Link to="/profile" className="buttonProfile__container">
      {userDetails?.profileImage ? (
        <img
          src={userDetails?.profileImage}
          alt="userImg"
          className="buttonProfile__image"
        />
      ) : (
        <img src={avatar} alt="userImg" className="buttonProfile__image" />
      )}
      <div className="buttonProfile__text-container">
        <p className="buttonProfile__text">{userDetails?.name}</p>
        <p className="buttonProfile__text">{userDetails?.surname}</p>
      </div>
    </Link>
  );
};
