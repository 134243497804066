import "./NewsBox.css";
import { ShortenText } from "../components/functions";

export const NewsBox = ({ title, content, image, onClick }) => {
  return (
    <div className="newsbox__container" onClick={onClick}>
      <div className="newsbox__text-container">
        <p className="newsbox__title">{ShortenText(title, 100)}</p>
        <div
          className="newsbox__text"
          dangerouslySetInnerHTML={{ __html: ShortenText(content, 100) }}
        />
      </div>
      <div className="newsbox__image-container">
        <img src={image} className="newsbox__image" alt="news-img" />
      </div>
    </div>
  );
};
