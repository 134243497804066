import "./WeatherCard.css";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { tR, weatherIcons } from "../components/functions";
import { useUser } from "../context/UserContext";
import dayjs from "dayjs";
import "dayjs/locale/tr";
dayjs.locale("tr");

const weatherBgs = {
  "01d": "#00c4ff", //  clear sky
  "02d": "#00c4ff", //  few clouds
  "03d": "#00c4ff", //  scattered clouds
  "04d": "#00c4ff", //  broken clouds
  "09d": "#555", //  shower rain
  "10d": "#555", //  rain
  "11d": "#555", //  thunderstorm
  "13d": "#00c4ff", //  snow
  "50d": "#555", //  mist
  init: "#00c4ff",
};

const WeatherCard = () => {
  const { userDetails } = useUser();
  const [allWeather, setAllWeather] = useState(null);
  const [weather, setWeather] = useState([]);

  useEffect(() => {
    getAllWeather();
  }, []);

  const getAllWeather = async () => {
    const _weather = await tR(`/weather`, "GET");

    setAllWeather(_weather);
  };

  useEffect(() => {
    if (allWeather) {
      setWeather([
        allWeather.list[0],
        allWeather.list[8],
        allWeather.list[16],
        allWeather.list[24],
        allWeather.list[32],
        allWeather.list[39],
      ]);
    }
  }, [allWeather]);

  return (
    <div
      className="weather__container"
      style={{
        justifyContent: weather?.length > 0 ? null : "center",
        backgroundImage: `radial-gradient(circle at 85% 35%, #fff, ${
          weatherBgs[weather[0]?.weather[0]?.icon] || weatherBgs.init
        }`,
      }}
    >
      {weather.length > 0 ? (
        <>
          <div className="weather__top">
            <div className="weather__top--text-container">
              <p className="weather__city">{userDetails?.City}</p>
              <p className="weather__current-day">
                {dayjs().format("DD/MM/YYYY dddd")}
              </p>
              <p className="weather__degree">
                {weather[0]?.main?.temp.toFixed(0)} °C
              </p>
            </div>
            <div className="weather__current-container">
              <img
                src={weatherIcons[weather[0]?.weather[0]?.icon]}
                alt="current-day-weather-img"
                className="weather__current-image"
              />
            </div>
          </div>
          <div className="weather__bottom">
            {weather?.slice(1).map((day, index) => (
              <div className="weather__upcoming-day" key={index}>
                <p className="weather__upcoming-title">
                  {dayjs(day.dt_txt).format("ddd")}
                </p>
                <div className="weather__upcoming-image-container">
                  <img
                    src={weatherIcons[day.weather[0].icon]}
                    alt="weather-icon"
                    className="weather__upcoming-image"
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Spin
          indicator={
            <LoadingOutlined style={{ color: "#387d3b", fontSize: 45 }} />
          }
        />
      )}
    </div>
  );
};

export default WeatherCard;
