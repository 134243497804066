import "./LoginUser.css";
import { Form, Input, Space, Spin } from "antd";
import img01 from "../../assets/login_images/1.jpg";
import img02 from "../../assets/login_images/2.jpg";
import img03 from "../../assets/login_images/3.jpg";
import logo from "../../assets/DRT_Logo.png";
import { Link } from "react-router-dom";
import Carousel from "nuka-carousel";
import { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { LoadingOutlined } from "@ant-design/icons";

export const LoginUser = ({ handleLogin, handleMfa }) => {
  const [loginStep, setLoginStep] = useState(0);
  const [session, setSession] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");

  const [butDisabled, setButDisabled] = useState(true);

  useEffect(() => {
    var loginForm = document.getElementById("login-form");
    if (loginForm) {
      loginForm.addEventListener("keypress", function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          submitForm(e);
        }
      });
    }

    return () => {
      if (loginForm) {
        loginForm.removeEventListener("keypress", function (e) {
          var key = e.which || e.keyCode;
          if (key === 13) {
            submitForm(e);
          }
        });
      }
    };
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const loginPhone = String(
      document.getElementById("login-phone").value
    ).replace(/\s/g, "");
    setPhone(loginPhone);
    setLoginStep(3);

    const submitObject = {
      loginPhone,
    };

    const smsCheckStep = await handleLogin(submitObject);
    setSession(smsCheckStep.Session);

    if (smsCheckStep) {
      setLoginStep(1);
    }
  };

  const submitMfa = async (e) => {
    e.preventDefault();
    const code = document.getElementById("login-password");
    const loginPhone = document.getElementById("login-phone");
    setLoginStep(3);

    const submitObject = {
      mobile: phone,
      code: code.value,
      Session: session,
    };

    await handleMfa(submitObject);
  };

  return (
    <div className="loginUser__container">
      <div className="loginUser__black">
        <div></div> {/* for justify-content */}
        <div className="loginUser__black--login-form-container">
          <div className="loginUser__black--logo-container">
            <img src={logo} alt="drt-logo" className="loginUser__black--logo" />
          </div>
          <Form
            name="normal_login"
            className="loginUser__black--login-form"
            onFinish={submitForm}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitForm(e);
              }
            }}
          >
            {loginStep === 0 && (
              <Form.Item
                style={{
                  width: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Lütfen cep telefonunuzu giriniz.",
                  },
                ]}
              >
                <PhoneInput
                  country={"tr"}
                  inputStyle={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "30px",
                  }}
                  countryCodeEditable={false}
                  containerStyle={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  inputProps={{
                    id: "login-phone",
                    placeholder: "Cep Telefonu",
                  }}
                  onChange={(phone) => {
                    if (phone.length == 12) {
                      setButDisabled(false);
                    } else {
                      setButDisabled(true);
                    }
                  }}
                />
              </Form.Item>
            )}

            {loginStep === 1 && (
              <Form.Item
                className="loginUser__black--form-item"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şifrenizi giriniz.",
                  },
                ]}
              >
                <Input.Password
                  id="login-password"
                  placeholder="Şifre"
                  style={{
                    width: "300px",
                    height: "40px",
                    borderRadius: "30px",
                  }}
                />
              </Form.Item>
            )}

            {loginStep === 3 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "98px",
                  width: "100%",
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: "white",
                      }}
                    />
                  }
                />
              </div>
            )}

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    width: "300px",
                  }}
                >
                  {loginStep === 0 && (
                    <Link
                      to="/signup"
                      style={{
                        flex: 1,
                      }}
                    >
                      <button
                        className="loginUser__black--form-signup-button"
                        style={{
                          width: "100%",
                        }}
                      >
                        DRT ID Oluştur
                      </button>
                    </Link>
                  )}
                  <Link
                    to="/"
                    style={{
                      flex: 1,
                    }}
                  >
                    {loginStep === 0 && (
                      <button
                        disabled={butDisabled}
                        onClick={submitForm}
                        className="loginUser__black--form-login-button"
                        type="submit"
                        style={{
                          width: "100%",
                        }}
                      >
                        Şifre Gönder
                      </button>
                    )}
                    {loginStep === 1 && (
                      <button
                        onClick={submitMfa}
                        style={{
                          width: "300px",
                        }}
                        className="loginUser__black--form-login-button"
                        type="submit"
                      >
                        Giriş Yap
                      </button>
                    )}
                  </Link>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/loginstore"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button className="loginUser__black--login-store-button">
              Bayi Girişi
            </button>
          </Link>
        </div>
        <div className="loginUser__black--footer">
          <p className="loginUser__black--footer-text">
            Copyright © Doktor Tarsa Tüm hakları saklıdır.
          </p>
          <p className="loginUser__black--footer-text">v1.00</p>
        </div>
      </div>
      <div className="loginUser__green">
        <div className="loginUser__green--image-container">
          <Carousel withoutControls autoplay wrapAround autoplayInterval={3000}>
            <img className="loginUser__green--image" src={img01} alt="logo" />
            <img className="loginUser__green--image" src={img02} alt="logo" />
            <img className="loginUser__green--image" src={img03} alt="logo" />
          </Carousel>
        </div>
        <div className="loginUser__green--text-container">
          <p className="loginUser__green-header">
            Doktor Tarsa Connect <sup>©</sup>’e <br /> Hoşgeldiniz!
          </p>
          <p className="loginUser__green-text">
            Bitkilerin onayladığı 35 yılı aşkın tecrübesiyle <br /> Doktor
            Tarsa, artık daima yanınızda!
          </p>
        </div>
      </div>
    </div>
  );
};
