import { useState } from "react";

export const NeuButton = ({ children, className, style, onClick }) => {
  const [pressed, setPressed] = useState(false);

  return (
    <button
      onClick={onClick}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setTimeout(() => setPressed(false), 50)}
      onMouseLeave={() => setPressed(false)}
      className={className}
      style={{
        ...style,
        ...(pressed
          ? {
              filter:
                "drop-shadow(-1px -1px 2px rgba(179, 179, 179, 0.50)) drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.30))",
              boxShadow:
                "10px 10px 25px 0px rgba(147, 147, 147, 0.9) inset, -10px -10px 20px 0px rgba(255, 255, 255, 0.9) inset, 10px -10px 20px 0px rgba(147, 147, 147, 0.2) inset, -10px 10px 20px 0px rgba(147, 147, 147, 0.2) inset, -1px -1px 2px 0px rgba(147, 147, 147, 0.5), 1px 1px 2px 0px rgba(255, 255, 255, 0.3)",
            }
          : {}),
      }}
    >
      {children}
    </button>
  );
};
