import "./FusionPanel.css";
import { useState, useRef, useEffect } from "react";
import { Header } from "../components/Header";
import fusion from "../assets/fusion.png";
import { Switch } from "antd";
import play from "../assets/play.png";
import stop from "../assets/stop.png";
import powerSwitch1 from "../assets/powerSwitch1.png";
import powerSwitch2 from "../assets/powerSwitch2.png";
import { RoundSlider } from "mz-react-round-slider";
import ecgroup from "../assets/ecgroup.png";
import phgroup from "../assets/phgroup.png";
import { NeuButton } from "../components/customComponents/NeuButton";

const sulamaSaatleri = [
  { id: 1, status: "inactive", time: null },
  { id: 2, status: "active", time: "13:00" },
  { id: 3, status: "active", time: "15:30" },
  { id: 4, status: "active", time: "17:20" },
];

const buttonData = [
  { state: "stop", text: "Durdur", image: stop },
  { state: "play", text: "Başlat", image: play },
];

const ParcelSwitch = ({ num, checked, onChange }) => {
  return (
    <div className="fusion__parcel-row" key={num}>
      <div className="fusion__parcel-number">
        <p className="fusion__parcel-number-text">{num}</p>
      </div>
      <Switch
        className="fusion__parcel-switch"
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

export const FusionPanel = () => {
  const [sulamaSuresi, setSulamaSuresi] = useState(30);
  const [parcelVanasi, setParcelVanasi] = useState(Array(16).fill(false));
  const [fusionActive, setFusionActive] = useState(false);
  const [ec, setEc] = useState(2.4);
  const [ph, setPh] = useState(5.2);
  const [ecPointer, setEcPointer] = useState([{ value: ec * 3 }]);
  const [phPointer, setPhPointer] = useState([{ value: ph * 2 }]);
  const ecInputRef = useRef(null);
  const phInputRef = useRef(null);
  const [bigButtonState, setBigButtonState] = useState(null);

  const formatSulamaSuresi = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedTime = `${hours
      .toString()
      .padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}`;
    return formattedTime;
  };

  const handleParcelVanasiChange = (index) => {
    setParcelVanasi((prev) => {
      const updatedParcelVanasi = [...prev];
      updatedParcelVanasi[index] = !updatedParcelVanasi[index];
      return updatedParcelVanasi;
    });
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        ecInputRef.current &&
        !ecInputRef.current.contains(e.target) &&
        phInputRef.current &&
        !phInputRef.current.contains(e.target)
      ) {
        if (ec === null) {
          setEc(0);
          setEcPointer([{ value: 0 }]);
        }
        if (ph === null) {
          setPh(1);
          setPhPointer([{ value: 1 * 2 }]);
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ec, ph]);

  return (
    <div className="outer__container">
      <Header title="DRT Fusion© Paneli" />
      <div className="fusion__content">
        <div className="fusion__left-col">
          <div className="fusion__left-logo-container">
            <img src={fusion} alt="Fusion" className="fusion__fusion-logo" />
          </div>
          <div className="fusion__power-container">
            <NeuButton
              className="fusion__power-button-container"
              onClick={() => {
                setTimeout(() => {
                  setFusionActive(!fusionActive);
                }, 500);
              }}
            >
              <img
                src={fusionActive ? powerSwitch1 : powerSwitch2}
                alt="powerSwitch"
                className="fusion__power-button-image"
              />
            </NeuButton>
            <div className="fusion__power-screen-container">
              <p className="fusion__power-screen-text">
                {fusionActive ? `Çalışıyor...` : `Çalışmıyor...`}
              </p>
              <p className="fusion__power-screen-text">
                {fusionActive ? `Program Başlıyor.` : `Program Kapatılıyor.`}
              </p>
            </div>
          </div>
          <div className="fusion__left-controllers-container">
            <div className="fusion__left-controller">
              <div className="fusion__left-controller-screen">
                <input
                  ref={ecInputRef}
                  type="text"
                  className="fusion__left-controller-input"
                  style={{ color: "#80BA26" }}
                  value={ec}
                  onChange={(e) => {
                    let filteredValue = e.target.value
                      .replace(/[^0-9.,]/g, "")
                      .replace(/,/g, ".");

                    if (isNaN(filteredValue)) {
                      filteredValue = null;
                    } else if (filteredValue < 1) {
                      filteredValue = null;
                    } else if (filteredValue > 3) {
                      filteredValue = 3;
                    }

                    setEc(filteredValue);
                    setEcPointer([{ value: filteredValue * 3 }]);
                  }}
                />
                <p
                  className="fusion__left-controller-screen-text"
                  style={{ color: "#80BA26" }}
                >
                  <span>EC</span>
                </p>
              </div>
              <div className="fusion__slider-container">
                <div className="fusion__roundslider-container-ec">
                  <RoundSlider
                    pointers={ecPointer}
                    onChange={(value) => {
                      setEcPointer(value);
                      setEc((value[0].value / 3).toFixed(1));
                    }}
                    min={0}
                    max={9}
                    step={0.1}
                    arrowStep={0.1}
                    round={1}
                    pathStartAngle={90}
                    pathEndAngle={360}
                    hideText
                    pathRadius={Math.max(
                      35,
                      Math.min(85, window.innerWidth / 25)
                    )}
                    hideConnection
                    connectionBgColor="inherit"
                    pathBgColor="inherit"
                    pointerSVG={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="clamp(1rem, 2vw, 2rem)"
                        // height="clamp(1rem, 2vw, 2rem)"
                        width={30}
                        height={30}
                        cursor={"pointer"}
                        viewBox="0 0 32 32"
                        fill="#e2e2e2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          d="M4.52519 11.1648L1.9763 6.75C0.629152 4.41667 2.31309 1.5 5.00739 1.5L10.1052 1.5C12.7995 1.5 14.4834 4.41667 13.1363 6.75L10.5874 11.1648C9.24022 13.4981 5.87234 13.4981 4.52519 11.1648Z"
                          stroke="#80BA26"
                          strokeWidth="3"
                          transform={`rotate(${
                            ((ec - 0) / (3 - 0)) * 270
                          } 8 8)`}
                        />
                      </svg>
                    }
                  />
                </div>
                <img
                  src={ecgroup}
                  alt="ecgroup"
                  className="fusion__group-img"
                />
              </div>
            </div>
            <div className="fusion__left-controller">
              <div className="fusion__left-controller-screen">
                <input
                  ref={phInputRef}
                  type="text"
                  className="fusion__left-controller-input"
                  style={{ color: "#FF7A00" }}
                  value={ph}
                  onChange={(e) => {
                    let filteredValue = e.target.value
                      .replace(/[^0-9.,]/g, "")
                      .replace(/,/g, ".");

                    if (isNaN(filteredValue)) {
                      filteredValue = null;
                    } else if (filteredValue < 1) {
                      filteredValue = null;
                    } else if (filteredValue > 9) {
                      filteredValue = 9;
                    }

                    setPh(filteredValue);
                    setPhPointer([{ value: filteredValue * 2 }]);
                  }}
                />
                <p
                  className="fusion__left-controller-screen-text"
                  style={{ color: "#FF7A00" }}
                >
                  <span>pH</span>
                </p>
              </div>
              <div className="fusion__slider-container">
                <div className="fusion__roundslider-container-ph">
                  <RoundSlider
                    pointers={phPointer}
                    onChange={(value) => {
                      setPhPointer(value);
                      setPh((value[0].value / 2).toFixed(1));
                    }}
                    min={2}
                    max={18}
                    step={0.1}
                    arrowStep={0.1}
                    round={1}
                    pathStartAngle={180}
                    pathEndAngle={360}
                    hideText
                    pathRadius={Math.max(
                      35,
                      Math.min(75, window.innerWidth / 27)
                    )}
                    hideConnection
                    connectionBgColor="inherit"
                    pathBgColor="inherit"
                    pointerSVG={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="clamp(1rem, 2vw, 2rem)"
                        // height="clamp(1rem, 2vw, 2rem)"
                        width={30}
                        height={30}
                        cursor={"pointer"}
                        viewBox="0 0 32 32"
                        fill="#e2e2e2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="svgClass"
                      >
                        <path
                          d="M3.25 4.52523L7.6648 1.97634C9.99814 0.629191 12.9148 2.31313 12.9148 5.00743L12.9148 10.1052C12.9148 12.7995 9.99814 14.4835 7.66481 13.1363L3.25 10.5874C0.916667 9.24026 0.916667 5.87238 3.25 4.52523Z"
                          stroke="#FF7A00"
                          strokeWidth="3"
                          transform={`rotate(${
                            ((ph - 1) / (9 - 1)) * 180
                          } 8 8)`}
                        />
                      </svg>
                    }
                  />
                </div>
                <img
                  src={phgroup}
                  alt="phgroup"
                  className="fusion__group-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="fusion__middle-col">
          <p className="fusion__green-button">Sulama Süresi</p>
          <div className="fusion__irrigation-time-container">
            <p className="fusion__irrigation-time">
              {formatSulamaSuresi(sulamaSuresi)}
            </p>
          </div>
          <div className="fusion__buttons-div">
            <NeuButton
              onClick={() => setSulamaSuresi((prev) => Math.max(prev - 5, 0))}
              className="fusion__button fusion__minus-button"
            >
              -
            </NeuButton>
            <NeuButton
              onClick={() => setSulamaSuresi((prev) => prev + 5)}
              className="fusion__button fusion__plus-button"
            >
              +
            </NeuButton>
          </div>
          <p className="fusion__green-button">Sulama Saatleri</p>
          <div className="fusion__hours-container">
            {sulamaSaatleri.map((sulamaSaati) => (
              <div key={sulamaSaati.id} className="fusion__hour-row">
                <input
                  maxLength={5}
                  value={sulamaSaati.time}
                  style={{
                    backgroundColor:
                      sulamaSaati.status === "active"
                        ? "#e2e2e2"
                        : "rgba(192, 255, 57, 0.28)",
                  }}
                  className="fusion__hour"
                />
                <NeuButton
                  style={{
                    backgroundColor:
                      sulamaSaati.status === "active"
                        ? "#e2e2e2"
                        : "rgba(192, 255, 57, 0.28)",
                  }}
                  className="fusion__hour-button"
                >
                  {sulamaSaati.status === "active" ? "-" : "+"}
                </NeuButton>
              </div>
            ))}
          </div>
        </div>
        <div className="fusion__right-col">
          <div className="fusion__right-top">
            <div className="fusion__parcels-container">
              <p
                className="fusion__green-button"
                style={{ width: "50%", marginBottom: 20 }}
              >
                Parsel Vanaları
              </p>
              <div className="fusion__parcels">
                {parcelVanasi.map((checked, index) => (
                  <ParcelSwitch
                    key={index}
                    num={index + 1}
                    checked={checked}
                    onChange={() => handleParcelVanasiChange(index)}
                  />
                ))}
              </div>
            </div>
            <div className="fusion__right-right-container">
              <div className="fusion__right-buttons-container">
                <NeuButton className="fusion__right-program-button">
                  Programı Kaydet
                </NeuButton>
                <NeuButton className="fusion__right-program-button">
                  Kayıtlı Programlar
                </NeuButton>
                <NeuButton className="fusion__right-program-button">
                  DRT Fusion Ayarları
                </NeuButton>
              </div>
              <div className="fusion__right-empty-container">
                <p>DRT Fusion </p>
                <p>EDKHSJG088</p>
                <p>2024</p>
                <p>V.001</p>
              </div>
            </div>
          </div>
          <div className="fusion__right-bottom-container">
            {buttonData.map((button) => (
              <NeuButton
                key={button.state}
                onClick={() => {
                  if (bigButtonState === button.state) {
                    setBigButtonState(null);
                  } else {
                    setBigButtonState(button.state);
                  }
                }}
                className={`fusion__right-bottom-button-container ${
                  bigButtonState === button.state
                    ? "fusion__right-bottom-button-container--active"
                    : ""
                }`}
                style={{
                  width: window.innerHeight < 700 ? 64 : 140,
                  height: window.innerHeight < 700 ? 64 : 240,
                }}
              >
                {window.innerHeight > 700 ? (
                  <p className="fusion__right-bottom-button-text">
                    {button.text}
                  </p>
                ) : null}
                <img
                  src={button.image}
                  alt="buttonimg"
                  className="fusion__right-bottom-button-image"
                  style={{ width: window.innerHeight < 700 ? "50%" : "33%" }}
                />
              </NeuButton>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
