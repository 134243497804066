import "./CreateNewProgram.css";
import { Header } from "../../components/Header";
import { Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { YieldBox } from "../../components/YieldBox";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/noImage.png";
import { tR } from "../../components/functions";
import { NotFoundContent } from "../../components/NotFoundContent";
import { PopUpMessage } from "../../components/PopUpMessage";

const sulamaTipleri = [
  { key: "damla", value: "damla", text: "Damla Sulama" },
  { key: "yagmur", value: "yagmur", text: "Yağmurlama Sulama" },
  { key: "salma", value: "salma", text: "Salma Sulama" },
];

const ValueInput = ({ label, value, onChange, analizMetodu }) => (
  <div className="createnewprogram__value-row">
    <p className="createnewprogram__value-title">{label}</p>
    <input
      onChange={(e) => onChange(e.target.value)}
      onKeyPress={(e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46 &&
          charCode !== 44 &&
          charCode !== 8 &&
          charCode !== 9
        ) {
          e.preventDefault();
        }
      }}
      className="createnewprogram__value-input"
      type="text"
      value={value}
    />
    <p className="createnewprogram__value-analizmetodu">{analizMetodu}</p>
  </div>
);

export const CreateNewProgram = () => {
  let navigate = useNavigate();
  const [programList, setProgramList] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedIrrigationType, setSelectedIrrigationType] = useState(null);
  const [selectedVerimGrubu, setSelectedVerimGrubu] = useState(null);
  const [nitrogen, setNitrogen] = useState(null);
  const [phosphorus, setPhosphorus] = useState(null);
  const [potassium, setPotassium] = useState(null);
  const [calciumOxide, setCalciumOxide] = useState(null);
  const [magnesiumOxide, setMagnesiumOxide] = useState(null);
  const [sulphur, setSulphur] = useState(null);
  const [p2o5Ph, setP2o5Ph] = useState(null);

  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const _programList = await tR("/schedule-requests/program", "GET");
    setProgramList(_programList);
  };

  useEffect(() => {
    const programs = programList?.[selectedPlant]?.programs || [];
    if (programs.length === 1) {
      setSelectedProgram(programs[0].key);
    }
  }, [selectedPlant, programList]);

  useEffect(() => {
    if (selectedPlant && selectedProgram && selectedVerimGrubu) {
      handleSubmit();
    }
  }, [selectedVerimGrubu]);

  const handleSubmit = async () => {
    const submitObject = {
      Nitrogen: nitrogen || 0.12,
      Phosphorus: phosphorus || 23,
      Potassium: potassium || 600,
      CalciumOxide: calciumOxide || 7200,
      MagnesiumOxide: magnesiumOxide || 750,
      Sulphur: sulphur || 2.5,
      P2O5Ph: p2o5Ph || 7,
      program: selectedProgram,
      fertilizerSet: selectedVerimGrubu,
      irrigationType: selectedIrrigationType,
    };

    tR("/schedule-requests", "POST", submitObject).then((program) => {
      if (program) {
        const programId = program.scheduleRequest.id;

        navigate(`/programdetail/${programId}`, {
          state: { program },
        });
      } else {
        PopUpMessage({
          type: "error",
          content: `Program oluşturulamadı. Analiz sonuçlarınızı kontrol ederek yeniden deneyiniz.`,
          duration: 5,
        });
        setSelectedVerimGrubu(null);
      }
    });
  };

  return (
    <div className="outer__container">
      <Header title="Gübreleme Programı" showBackButton />
      <div className="createnewprogram__content">
        <div className="createnewprogram__left">
          <div className="createnewprogram__left-content">
            <p className="createnewprogram__title">
              Bitkinizi ve Sulama Tipinizi Seçin
            </p>
            <div
              className="createnewprogram__select-container"
              style={{
                justifyContent: selectedPlant ? "space-evenly" : "flex-start",
                gap: selectedPlant ? "0" : "30px",
                paddingTop: selectedPlant
                  ? "0"
                  : "clamp(10px, -1.38rem + 2.62vw, 45px)",
              }}
            >
              <Select
                notFoundContent={
                  <NotFoundContent message="Bitki bulunamadı." />
                }
                className="createnewprogram__select"
                showSearch
                placeholder="Bitki"
                optionFilterProp="children"
                onChange={(value) => {
                  setSelectedPlant(value);
                  setSelectedProgram(null);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={
                  programList
                    ? Object.keys(programList).map((plantKey) => ({
                        value: plantKey,
                        label: programList[plantKey]?.plantName || "",
                      }))
                    : []
                }
                suffixIcon={""}
              />
              {selectedPlant ? (
                <div className="createnewprogram__plant-image-container">
                  <img
                    className="createnewprogram__plant-image"
                    src={programList?.[selectedPlant]?.image || noImage}
                    alt="plant"
                  />
                </div>
              ) : null}
              <Select
                notFoundContent={
                  <NotFoundContent message="Program bulunamadı." />
                }
                className="createnewprogram__select"
                showSearch
                placeholder="Program"
                optionFilterProp="children"
                value={selectedProgram}
                onChange={(value) => setSelectedProgram(value)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={
                  selectedPlant
                    ? (programList?.[selectedPlant]?.programs || []).map(
                        (program) => ({
                          value: program.key,
                          label: program.name,
                        })
                      )
                    : []
                }
                disabled={!selectedPlant}
                suffixIcon={""}
              />
              <Select
                notFoundContent={
                  <NotFoundContent message="Sulama tipi bulunamadı." />
                }
                className="createnewprogram__select"
                showSearch
                placeholder="Sulama Tipi"
                optionFilterProp="children"
                onChange={(value, option) =>
                  setSelectedIrrigationType(option.label)
                }
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={sulamaTipleri.map((item) => ({
                  value: item.key,
                  label: item.text,
                }))}
                suffixIcon={""}
              />
            </div>
          </div>
        </div>
        <div className="createnewprogram__middle">
          <p className="createnewprogram__title">Analiz Değerlerinizi Girin</p>
          <div className="createnewprogram__values-container">
            <ValueInput
              label="N"
              value={nitrogen}
              onChange={setNitrogen}
              analizMetodu={"Teorik"}
            />
            <ValueInput
              label="P₂O₅"
              value={phosphorus}
              onChange={setPhosphorus}
              analizMetodu={"Spektrofotometrik"}
            />
            <ValueInput
              label="K₂O"
              value={potassium}
              onChange={setPotassium}
              analizMetodu={"A.A/ICPOES"}
            />
            <ValueInput
              label="CaO"
              value={calciumOxide}
              onChange={setCalciumOxide}
              analizMetodu={"A.A/ICPOES"}
            />
            <ValueInput
              label="MgO"
              value={magnesiumOxide}
              onChange={setMagnesiumOxide}
              analizMetodu={"A.A/ICPOES"}
            />
            <ValueInput label="S" value={sulphur} onChange={setSulphur} />
            <ValueInput label="pH" value={p2o5Ph} onChange={setP2o5Ph} />
          </div>
          <p className="createnewprogram__middle-bottom-text">
            Eğer analiz sonucunuz yoksa tabloyu boş bırakabilirsiniz. Önerilen
            program, sizin için en uygun gübreleme stratejisini belirlemenize
            yardımcı olacaktır.
          </p>
        </div>
        <div className="createnewprogram__right">
          <p className="createnewprogram__title">Verim Grubunuzu Seçin</p>
          <div className="createnewprogram__yield-goal-container">
            {selectedVerimGrubu ? (
              <>
                <Spin
                  indicator={<LoadingOutlined style={{ color: "#387d3b" }} />}
                />
                <p
                  style={{ color: `var(--text-color03)`, textAlign: "center" }}
                >
                  Size özel gübreleme programınız oluşturuluyor...
                </p>
              </>
            ) : (
              <>
                <YieldBox
                  yieldGoal="maximumFertilizers"
                  yieldGoalName="Maksimum Verim"
                  onClick={() => setSelectedVerimGrubu("maximumFertilizers")}
                  disabled={!selectedPlant || !selectedProgram}
                />
                <YieldBox
                  yieldGoal="highFertilizers"
                  yieldGoalName="Yüksek Verim"
                  onClick={() => setSelectedVerimGrubu("highFertilizers")}
                  disabled={!selectedPlant || !selectedProgram}
                />
                <YieldBox
                  yieldGoal="optimumFertilizers"
                  yieldGoalName="Optimum Verim"
                  onClick={() => setSelectedVerimGrubu("optimumFertilizers")}
                  disabled={!selectedPlant || !selectedProgram}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
