import "./SearchBar.css";
import { Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { tR } from "./functions";
import { NotFoundContent } from "./NotFoundContent";

export const SearchBar = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const _products = await tR("/products", "GET");
    setProducts(_products);
  };

  const updatedProducts = products?.map((product) => ({
    ...product,
    label: product.name,
    value: product.id,
  }));

  return (
    <div className="searchBar__container">
      <Select
        notFoundContent={<NotFoundContent message="Ürün bulunamadı." />}
        className="searchBar__search"
        showSearch
        open={showOptions}
        placeholder="İstediğin ürünü kolayca bul…"
        optionFilterProp="children"
        onSearch={(value) => setShowOptions(value.length > 0)}
        onSelect={(_, option) => {
          navigate(`/products`, { state: { option } });
          if (location.pathname === "/products") {
            window.location.reload();
          }
        }}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={updatedProducts}
        suffixIcon={""}
      />
      <div className="searchBar__icon searchBar__left-icon">
        <SearchOutlined style={{ fontSize: 20 }} />
      </div>
    </div>
  );
};
